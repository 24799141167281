import { Route, Routes as DomRoutes,useLocation, Navigate } from "react-router-dom";
import { useEffect } from 'react';
import React from 'react';

import Detail from "../pages/booking/detail";
import RSVP from "../pages/booking/rsvp";
import Dashboard from "../pages/dashboard";
import CreateEvents from "../pages/events/create";
import AnalyticsEvent from "../pages/events/analytics";
import ShareAnalyticsEvent from "../pages/events/shareAnalytics";
import EmbedEvent from "../pages/events/embed";
import Events from "../pages/events/index";
import FaqEventPlanner from "../pages/faq/faqeventplanner";
import Home from "../pages/index";
import EventDetail from "../pages/portalys/eventdetail";
import GuestList from "../pages/portalys/guestlist";
import Account from "../pages/settings/settings";
import OrganizerDetail from "../pages/settings/organizerDetail";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Users from "../pages/users/users";
import Orders from "../pages/admin/orders";
import Verifiers from "../pages/admin/orders/verifier";
import SMSBlasts from "../pages/sms-emails/sms";
import EmailBlasts from "../pages/sms-emails/emails";
import Follow from "../pages/settings/follow/follow";
import { useMediaQuery } from "../utils/useMediaQuery";
import SelectEvent from "../pages/verifier/selectevent";
import Scanner from "../pages/verifier/scanner";
import ListAttendee from "../pages/verifier/listattendee";
import ListGuest from "../pages/verifier/guests";
import NotAvailable from "../pages/portalys/notavailable";
import Validate from "../pages/verifier/validate";
import ValidateGuest from "../pages/verifier/validateguest";
import SendLink from "../pages/sendlink";
import Links from "../pages/trackinglinks/index";
import Promo from "../pages/promocodes/index";
import Guests from "../pages/guestlist/index";
import Track from "../pages/trackinglinks/track";
import SignUpOrganizer from "../modules/pages/SignUpOrganizer";
import LoginOrganizer from "../modules/pages/LoginOrganizer";
import Organizrs from "../pages/users/organizers";
import CompleteRegistration from "../modules/pages/CompleteRegistration";
import Wishlist from "../pages/wishlist";
import MyTickets from "../pages/myTickets";
import Settings from "../pages/profileSettings";
import BottomNavbar from "../modules/shared/bottomNavbar";
import ContactUs from "../pages/contactUs";
import OnboardingEvent from "../pages/events/onboarding";
import EventEmbed from "../pages/portalys/embedding/eventembed";
import SimpleEmbed from "../pages/portalys/embedding/simpleembed";
import { PageNotFound } from "../pages/portalys/event-components/pageNotFound";


/**
 * This is a function that returns a set of routes for a web application using React Router.
 * @param props - The `props` parameter is an object that contains all the properties passed down to
 * the `Routes` component from its parent component. These properties can include data, functions, and
 * other components that are needed for the `Routes` component to function properly. The `props`
 * parameter is used to access these
 * @returns The `Routes` component is being returned, which contains a series of `Route` components
 * with different paths and elements.
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
const Routes = (props) => {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <React.Fragment>
      {/* Include ScrollToTop within a container */}
      <ScrollToTop />
    <DomRoutes>
      <Route
        key="home"
        path="/"
        element={
          <BottomNavbar>
            <Home {...props} />
          </BottomNavbar>
        }
      />
      <Route
        key="claimticket"
        path="/claimticket"
        element={<Home {...props} />}
      />
      <Route
        path="/verifier/selectevent"
        element={isMobile ? <SelectEvent {...props} /> : <NotAvailable />}
      />
      <Route
        path="/verifier/scanner/:id"
        element={isMobile ? <Scanner {...props} /> : <NotAvailable />}
      />
      <Route
        path="/verifier/list/:id"
        element={isMobile ? <ListAttendee {...props} /> : <NotAvailable />}
      />
      <Route
        path="/verifier/guest/:id"
        element={isMobile ? <ListGuest {...props} /> : <NotAvailable />}
      />
      <Route
        path="/verifier/validate/:walletAddress/:eventuuid"
        element={isMobile ? <Validate {...props} /> : <NotAvailable />}
      />
      <Route
        path="/verifier/validate/guest/:instagram/:eventuuid"
        element={isMobile ? <ValidateGuest {...props} /> : <NotAvailable />}
      />
      <Route
        key="create-event"
        path="create-event"
        element={<OnboardingEvent {...props} />}
      />
          <Route
      path="/signuporganizer"
      element={<Navigate to="/create-event" replace />}
    />
      <Route
        key="loginOrganizer"
        path="loginOrganizer"
        element={<LoginOrganizer {...props} />}
      />
      <Route
        key="completeRegistration"
        path="completeRegistration/:email"
        element={<CompleteRegistration {...props} />}
      />
      <Route
        key="eventdetail"
        path="event/:id"
        element={
            <EventDetail {...props} />
        }
      />
            <Route
        key="eventembed"
        path="embed/event/:id"
        element={
            <EventEmbed {...props} />
        }
      />
                  <Route
        key="eventsimpleembed"
        path="simple-embed/event/:id"
        element={
            <SimpleEmbed {...props} />
        }
      />
      <Route
        path="event/:id/guestlist"
        element={
            <GuestList {...props} />
        }
      />
       <Route
        path="embed/event/:id/guestlist"
        element={
            <GuestList {...props} />
        }
      />
      <Route
        key="eventdetail"
        path="event/:promoterSlug/:id"
        element={
            <EventDetail {...props} />
        }
      />

      <Route
        key="dashboard"
        path="dashboard"
        element={<Dashboard {...props} />}
      />
      <Route key="events" path="events" element={<Events {...props} />} />
      <Route
        key="createEvent"
        path="events/create"
        element={<CreateEvents {...props} />}
      />
      <Route
        key="editEvent"
        path="events/edit/:id"
        element={<CreateEvents {...props} />}
      />
            <Route
        key="analyticsEvent"
        path="events/analytics/:id"
        element={<AnalyticsEvent {...props} />}
      />
                  <Route
        key="shareAnalyticsEvent"
        path="events/analytics/share/:id/:random"
        element={<ShareAnalyticsEvent {...props} />}
      />
                  <Route
        key="embed"
        path="events/embed/:id"
        element={<EmbedEvent {...props} />}
      />

      <Route
        key="bookingSeat"
        path="booking/rsvp"
        element={<RSVP {...props} />}
      />
      <Route
        key="bookingDetail"
        path="booking/detail"
        element={<Detail {...props} />}
      />
      <Route
        key="mytickets"
        path="/mytickets"
        element={
          <BottomNavbar>
            <MyTickets {...props} />
          </BottomNavbar>
        }
      />
      <Route
        key="wishlist"
        path="/wishlist"
        element={
          <BottomNavbar>
            <Wishlist {...props} />
          </BottomNavbar>
        }
      />
      <Route
        key="contactus"
        path="/contact-us"
        element={
          <BottomNavbar>
            <ContactUs {...props} />
          </BottomNavbar>
        }
      />

      <Route
        key="settings"
        path="/settings"
        element={
          <BottomNavbar>
            <Settings {...props} />
          </BottomNavbar>
        }
      />


      <Route
        key="qr"
        path="sendlink/:id"
        element={
            <SendLink {...props} />
        }
      />
      <Route
        path="track/:eventSlug/:trackingId"
        element={<Track {...props} />}
      />
      <Route path="faq" element={<FaqEventPlanner {...props} />} />
      <Route path="creator/:id" element={<Follow {...props} />} />
      <Route path="account" element={<Account {...props} />} />
      <Route path="verifyemail" element={<Home {...props} />} />
      <Route path="organizers" element={<Organizrs {...props} />} />
      <Route path="organizers/:id" element={<OrganizerDetail {...props} />} />
      <Route path="attendees" element={<Users {...props} />} />
      <Route path="team" element={<Orders {...props} />} />
      <Route path="trackinglinks" element={<Links {...props} />} />
      <Route path="guestlist" element={<Guests {...props} />} />
      <Route path="promocodes" element={<Promo {...props} />} />
      <Route path="verifiers" element={<Verifiers {...props} />} />
      <Route path="blasts/sms" element={<SMSBlasts {...props} />} />
      <Route path="blasts/email" element={<EmailBlasts {...props} />} />
      <Route path="*" element={<PageNotFound/>} />
    </DomRoutes>
    </React.Fragment>
  );
};

export default Routes;
