import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { AiFillHeart } from "react-icons/ai";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import Layout from "../../modules/layout/layout";
import { useMediaQuery } from "../../utils/useMediaQuery";
import { user } from "../../recoil/atoms/user";
import { getAllEventsLiked } from "../../utils/actions/event";
import { formatDate, formatTime } from "../portalys/newsevents";
import { theme } from "../../styles/theme/base";

function Wishlist(props) {
  const navigate = useNavigate();

  const isTablet = useMediaQuery("(max-width: 1192px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [_, setUser] = useRecoilState(user);
  const [wishlistEvents, setWishlistEvents] = useState([]);

  useEffect(() => {
    init();
  }, [_?.userData?.userId]);

  const init = async () => {
    window.scrollTo(0, 0);
    try {
      if (_.userData?.userId) {
        const likedEventsData = await getAllEventsLiked(_?.userData?.userId,_?.token);
        setWishlistEvents(Object.values(likedEventsData.likedEventsData)); // Convert likedEventsData to an array
      }
    } catch (error) {
      console.error({ error });
    }
  };

  return (
    <Layout {...props}>
      <Box
        maxW={"73.75rem"}
        padding={isTablet ? "0 1.25rem" : "0"}
        margin={"0 auto 5.31rem"}
      >
        <Text
          textAlign={"center"}
          margin={isMobile ? "2.19rem auto" : "5.31rem auto"}
          fontSize={isMobile ? "1.75rem" : "2.375rem"}
          className="gordita600"
          lineHeight={"150%"}
          letterSpacing={"-0.02375rem"}
          textTransform={"capitalize"}
        >
          Wishlist
        </Text>
        {wishlistEvents.length ? (
          <Box
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={"0.62rem"}
            rowGap={isMobile ? "0.62rem" : "1.25rem"}
            columnGap={"1.25rem"}
            flexWrap="wrap"
          >
            {wishlistEvents.map(
              (
                {
                  coverPicture: image,
                  eventName: heading,
                  organizer,
                  startDate: sdate,
                  startTime: time,
                  endTime: etime,
                  track,
                  categories,
                  city,
                  country,
                  uuid,
                  isLiked,
                  eventId,
                  organizedBy,
                  slug,
                  timezone,
                },
                index
              ) => {
                const formattedDate = formatDate(sdate, timezone);
                const formattedTime = formatTime(time);
                const formattedETime = formatTime(etime);
                return (
                  <Box
                    onClick={() => navigate("/event/" + slug)}
                    key={index}
                    sx={theme.card.primary.shape}
                    css={{
                      height: isMobile ? "7rem" : "31.938rem",
                      display: isMobile ? "flex" : "unset",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "0.62rem",
                      padding: isMobile
                        ? "0.31rem 0.94rem 0.31rem 0.31rem"
                        : "0.62rem 0.62rem 1.56rem",
                      cursor: "pointer",
                      borderRadius: isMobile ? "0.9375rem" : "1.5625rem",
                    }}
                  >
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      pos={"relative"}
                      flex={"0 0 auto"}
                    >
                      <Box
                        width="2.75rem"
                        height="2.75rem"
                        flexShrink="0"
                        borderRadius="6.25rem"
                        border="1px solid rgba(255, 255, 255, 0.05)"
                        bg={"rgba(0, 0, 0, 0.50)"}
                        backdropFilter={"blur(150px)"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        pos={"absolute"}
                        right={isMobile ? "unset" : "0.94rem"}
                        top={isMobile ? "unset" : "0.94rem"}
                      >
                        <Icon
                          as={AiFillHeart}
                          color="white.100"
                          w="1.5rem"
                          h="1.5rem"
                        />
                      </Box>
                      <Image
                        w={isMobile ? "6.375rem" : "100%"}
                        h={isMobile ? "6.375rem" : "22.5rem"}
                        objectFit={"cover"}
                        borderRadius="var(--radius-radius-big, 0.9375rem)"
                        src={image}
                      />
                    </Box>

                    <VStack
                      margin="1.25rem 0.62rem"
                      display="block"
                      gap={"0.62rem"}
                      overflow={"hidden"}
                    >
                      <Heading
                        color="#FBFAFB"
                        className="gordita700"
                        fontSize="1.4375rem"
                        textTransform={"capitalize"}
                        style={{
                          maxWidth: "22.5rem",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {heading}
                      </Heading>

                      <HStack
                        fontSize={{ base: 14, md: 16, "3xl": 16 }}
                        w="100%"
                        className="gordita400"
                      >
                        <Text className="gordita600" whiteSpace={"nowrap"}>
                          {formattedDate}
                        </Text>
                        <Text whiteSpace={"nowrap"}>
                          {formattedTime + " - " + formattedETime}
                        </Text>
                      </HStack>

                      <HStack
                        fontSize={{ base: 14, md: 16, "3xl": 16 }}
                        w="100%"
                        className="gordita400"
                      >
                        <Text
                          style={{
                            width: "50%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {organizedBy?.name}
                        </Text>
                        <Text
                          color="rgba(255, 255, 255, 0.60)"
                          fontSize="1rem"
                          style={{
                            width: "50%",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {city || ""} {city && country ? ", " : ""}{" "}
                          {country || ""}
                        </Text>
                      </HStack>
                    </VStack>
                  </Box>
                );
              }
            )}
          </Box>
        ) : (
          <VStack>
            <Text marginTop="4rem" fontSize="1.25rem" fontWeight="700">
              You have no liked events yet
            </Text>
            <Button
              marginTop="1.56rem"
              sx={theme.buttons.secondary.shape}
              bg={theme.buttons.secondary.bgColor}
              color={theme.buttons.secondary.color}
              border={theme.buttons.secondary.border}
              _hover={{
                borderColor: theme.buttons.secondary.hoverBorderColor,
              }}
              _active={{
                bg: theme.buttons.secondary.pressed,
              }}
              style={{
                width: "15.75rem",
              }}
              onClick={() => navigate("/")}
            >
              Find Events!
            </Button>
          </VStack>
        )}
      </Box>
    </Layout>
  );
}

export default Wishlist;
