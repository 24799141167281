import {
    HStack,
    InputGroup,
    VStack,
    InputRightElement,
    Input as InputComponent,
    Box,
    useColorModeValue,
    Text,
    Switch,
    Flex,
  } from "@chakra-ui/react";
  import { Checkbox, Input, Select } from "antd";
  import {
    DateBox,
    InputBox,
    SelectBox,
    SwitchBox,
    TextBox,
    TimeBox,
  } from "./SharedModules";
  import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete";
  import { DownOutlined, UserOutlined } from "@ant-design/icons";
  import { Button as ABT, Dropdown, Space } from "antd";
  import { theme } from "../../../../styles/theme/base";
  import { useState, useEffect } from "react";
  import LocationAutocomplete from "../../../../utils/autocomplete";
  import secrets from "../../../../secrets";
  import { message } from "antd";
  import { useMediaQuery } from "../../../../utils/useMediaQuery";
  import languageIcon from "../../../../static/assets/images/language.svg";
  import { useLocation } from "react-router-dom";
  
  import "./styles.css";
  
  /**
   * This is a React component that renders a form for creating basic events, including fields for event
   * name, type, start and end dates and times, location, and description.
   * @param props - The `props` parameter is an object that contains various properties passed down from
   * the parent component to the `BasicEvents` component. These properties include `handleBlur`,
   * `handleChange`, `values`, and `setFieldValue`, which are likely functions or state variables used to
   * manage form input and state. The
   * @returns The BasicEvents component is being returned, which renders a form with input fields for
   * event name, event type, start and end dates and times, location, and description. The event type
   * field is a dropdown menu with options for Night Club, Music, Techno, and Conference. The location
   * field uses the LocationAutocomplete component to allow users to search for and select a location
   * using the Google Maps API
   */
  const Language = (props) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const { handleBlur, handleChange, values, setFieldValue } = props;
    const textValue = useColorModeValue(
      theme.colors.black[100],
      theme.colors.white[100]
    );
    const [selectedEvent, setSelectedEvent] = useState(values.eventType || []);
    const location = useLocation();
    const shouldShowContent = location.pathname.includes("/edit");
  
    const handleCheckboxChange = (checkedValues) => {
      if (checkedValues.length <= 5) {
        setFieldValue("eventType", checkedValues);
        setSelectedEvent(checkedValues);
      } else {
        // Display a message or take appropriate action when the limit is reached
        message.warning("You can select up to 5 values.");
      }
    };
    
  
    useEffect(() => {
      setSelectedEvent(values.eventType || []);
    }, [values.eventType]);
  
    return (
      <VStack spacing={"2.19rem"} w="100%">

        <Box w="100%">
        <Flex alignItems="center" marginRight={50}>
            <Box
              width={"12.375rem"}
              height={"3.4375rem"}
              borderRadius={"6.25rem"}
              border={"1px solid rgba(255, 255, 255, 0.20)"}
              padding={"0.31rem"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              pos={"relative"}
            >
              <Box
                width={"2.8125rem"}
                height={"2.8125rem"}
                bg={"rgba(255, 255, 255, 0.05)"}
                borderRadius={"50%"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img
                  src={languageIcon}
                  alt={"language-icon"}
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                />
    
              </Box>
        <Select
                name="language"
                id="language"
                value={values.language || "en"}
                style={{
                  width: "100%",
                  position: "absolute",
                }}
                popupClassName="popup-currency-selector"
                className="currency-selector"
                onChange={(value) => setFieldValue("language", value)}
                options={[
                  {
                    value: "en",
                    label: "English",
                  },
                  {
                    value: "fr",
                    label: "French",
                  },
                  {
                    value: "es",
                    label: "Spanish",
                  },
                  // {
                  //   value: "it",
                  //   label: "Italian",
                  // },
                ]}
              />
                </Box>
          </Flex>
        </Box>
       
      </VStack>
    );
  };
  
  export default Language;
  