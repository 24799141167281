import Layout from "../../modules/layout/layout";
import AnalyticsPerEventComponent from "../../modules/pages/Events/analytics";
const ShareAnalyticsEvent = (props) => {
  return (
    <Layout>
      <AnalyticsPerEventComponent {...props} />
    </Layout>
  );
};

export default ShareAnalyticsEvent;
