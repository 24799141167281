import React, { useEffect, useState } from "react";
import { Box, Text, Grid } from "@chakra-ui/react";
import axios from "../../../../utils/api/axios";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import secrets from "../../../../secrets";

const StatsCard = ({ label, value, isCurrency }) => (
  <Box
    bg="black"
    borderRadius="lg"
    p={4}
    color="white"
    borderWidth="1px"
    borderColor="gray.600"
    textAlign="center"
    width="250px"
  >
    <Text fontSize="sm" color="gray.400" mb={2}>
      {label}
    </Text>
    <Text
      fontSize="2xl"
      fontWeight="bold"
      color={isCurrency ? "green.300" : "white"}
    >
      {isCurrency ? `$${value}` : value}
    </Text>
  </Box>
);

const DashboardStats = () => {
  const [_, setUser] = useRecoilState(user);
  const organizerId = _.userData?.organizerId || _.userData?.userId;
  const token = localStorage.getItem("x-auth-token");

  const [stats, setStats] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = "/api/emailAnalytics/" + organizerId;
        const response = await axios.get(url, {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        });
        const data = await response.data;
        setStats(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  if (!stats) {
    return <Text>Loading...</Text>;
  }

  if (secrets.environmentCrossmint === "staging") {
    <Text>Stats are not available in staging mode</Text>;
  }

  return (
    <>
      {secrets.environmentCrossmint === "staging" && (
        <Text marginLeft={4}>Email Stats are not available in staging mode. They are only available on production</Text>
      )}

      <Grid templateColumns="repeat(4, 1fr)" gap={4} m={4}>
        <StatsCard label="Total Emails Sent" value={stats.totalEmailsSent} />
        <StatsCard
          label="Delivery Rate"
          value={`${stats.deliveryRate.toFixed(2)}%`}
        />
        <StatsCard label="Open Rate" value={`${stats.openRate.toFixed(2)}%`} />
        <StatsCard
          label="Click Rate"
          value={`${stats.clickRate.toFixed(2)}%`}
        />
      </Grid>
    </>
  );
};

export default DashboardStats;
