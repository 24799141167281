import { React, useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  Image,
  Progress as LinearProgress,
  Heading,
  chakra,
  HStack,
  Button,
  Flex,
  Stack,
  Input,
  Checkbox,
  useDisclosure,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Radio,
  RadioGroup,
  useColorModeValue,
  useColorMode,
} from "@chakra-ui/react";
import Layout from "../../../modules/layout/layout";
import { seatUnSelected } from "../../../static/assets/images";
import { InputBox } from "../../../modules/pages/Events/Create/SharedModules";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { ticket } from "../../../recoil/atoms/tickets";
import { user } from "../../../recoil/atoms/user";
import { isEmpty } from "lodash";
import Unselected from "../../../static/assets/images/seatUnselectedSVG.svg";
import SelectedSvg from "../../../static/assets/images/selectedSVG.svg";
import secrets from "../../../secrets";
import withAuth from "../../../utils/withAuth";
import { userRoles } from "../../../utils/constants";
import { getTicketName } from "../../../utils/filters";
import { theme } from "../../../styles/theme/base";
import { CrossmintPaymentElement } from "@crossmint/client-sdk-react-ui";
import { getMaticPrice } from "../../../utils/crypto";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { useCrossmintEvents } from "@crossmint/client-sdk-react-ui";
import DarkLogo from "../../../static/assets/images/logoBlack.svg";
import LightLogo from "../../../static/assets/images/logo.svg";
import ArrowUpIcon from "../../../static/assets/images/arrowUpIcon.svg";
import ArrowDownIcon from "../../../static/assets/images/arrowDownIcon.svg";
import ArrowLeftIcon from "../../../static/assets/images/arrowLeftIcon.svg";
import axios from "../../../utils/api/axios";
import PhoneInput from "react-phone-input-2/";
import i18n from "i18next";
import translationEN from "../../portalys/locales/en.json";
import translationFR from "../../portalys/locales/fr.json";
import translationES from "../../portalys/locales/es.json";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  },
});

function RSVP(props) {
  const isMobile = useMediaQuery("(max-width: 905px)");
  const { colorMode, toggleColorMode } = useColorMode();
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const navigate = useNavigate();
  const [seeDetails, setSeeDetails] = useState(false);
  const [isTicket, setIsTicket] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [instagramHandle, setInstagramHandle] = useState("");
  const [showInstagram, setShowInstagram] = useState(false);
  const [WAnumber, setWAnumber] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");

  const [loading, setLoading] = useState(false);

  const updateUser = async () => {
    const token = _U?.token;
    const capitalizedFirstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1);
    const capitalizedLastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1);

    await axios
      .put(
        "/api/",
        {
          firstName: capitalizedFirstName,
          lastName: capitalizedLastName,
          socialLinks: JSON.stringify({
            ...socialLinks,
            instagram: instagramHandle,
          }),
          email,
        },
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        }
      )
      .catch((err) => {
        toast({
          title: "Data Updating Error",
          status: "error",
          isClosable: true,
          duration: 4000,
          position: "top-right",
        });
      });
  };

  const handleSubmit = async (event) => {
    try {
      setLoading(true);

      const walletAddress = _U.userData?.walletAddress;

      updateUser();

      event.preventDefault();

      setTimeout(async () => {
        try {
          const res = await axios.post(
            "/api/rsvp",
            {
              id: _.selectedEvent.uuid,
              ticketSelected: _?.ticketSelected,
              quantity: _.quantity,
              walletAddress,
              userId: _U.userData?.userId,
              promoter: _?.promoterSlug,
              perkSelected: _?.perkSelected,
              codeName: _?.codeName,
            },
            {
              headers: {
                "x-auth-token": _?.token,
              },
            }
          );

          if (res.status === 201) {
            const data = await res.data;
            // Display the error message to the user
            alert(data.error);
            window.history.back();
            throw new Error(data.error); // Throw an error to handle it further if needed
          } else {
            setTimeout(() => {
              navigate("/mytickets?rsvp=successful");
              window.location.reload(); // Reload the page
              setLoading(false);
            }, 2000);
          }
        } catch (error) {
          console.error("Error during fetch:", error);
          setLoading(false);
        }
      }, 2000);
    } catch (error) {
      console.error("Error during form submission:", error);
      setLoading(false);
    }
  };

  const desktopButtonStyle = {
    justifyContent: "center",
    mt: 4,
  };
  const mobileButtonStyle = {
    borderTopLeftRadius: "40px",
    borderTopRightRadius: "40px",
    height: "5.875rem",
    width: "100vw",
    bg: "rgba(0, 0, 0, 0.50)",
    backdropFilter: "blur(150px)",
    pos: "fixed",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 1.25rem",
    zIndex: 10,
  };

  useEffect(() => {
    i18n.changeLanguage(_?.language);
    if (!_?.ticketSelected) {
      navigate("/");
    }
    const hasInstagramTicket = _?.ticketSelected?.some(
      (ticket) => ticket?.instagram === true
    );

    if (hasInstagramTicket) {
      setShowInstagram(true);
    }
  }, []);
  useEffect(() => {
    setFirstName(_U?.userData?.firstName);
    setLastName(_U?.userData?.lastName);
    setEmail(_U?.userData?.email);
    setSocialLinks(_U.userData?.socialLinks);
    if (_U.userData?.socialLinks) {
      try {
        const socialLinksObj = _U.userData.socialLinks;

        // Check if Instagram handle exists
        if (socialLinksObj.instagram) {
          setInstagramHandle(socialLinksObj.instagram);
        }
      } catch (error) {
        console.error("Error parsing socialLinks JSON:", error);
      }
    }
  }, [_U?.userData]);

  const handlePhoneNumberChange = (value) => {
    // Check if the value is numeric or empty
    if (/^\d*$/.test(value) || value === "") {
      setWAnumber(value);
    }
  };
  return (
    <Box bg={_?.bgColor} minHeight={"100vh"} overflow={"scroll"}>
      <Box
        p="1.25rem"
        w="100%"
        h="100px"
        pos="fixed"
        display="flex"
        bg={_?.bgColor}
        justifyContent="center"
        alignItems="center"
        zIndex={"10"}
      >
        <Image
          alt=""
          width={"140px"}
          height={"44px"}
          src={colorMode === "light" ? DarkLogo : LightLogo}
        />
      </Box>
      {loading && (
        // Show loading indicator
        <div
          style={{
            margin: "0 auto",
            fontSize: "2rem",
            fontWeight: "700",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          Loading..
        </div>
      )}
      <Box mt="8rem">
        {isMobile ? (
          <HStack my={36} mx={"1.25rem"}>
            <Image
              onClick={() => navigate(-1)}
              cursor="pointer"
              alt=""
              src={ArrowLeftIcon}
            />
            <Heading textAlign="left" fontSize={23} className="gordita700">
              Checkout
            </Heading>
          </HStack>
        ) : (
          <Heading
            textAlign="center"
            my={36}
            fontSize={38}
            className="gordita700"
          ></Heading>
        )}

        <Box width="100vw" position="relative" mb={"2rem"}>
          {isMobile ? (
            <Box transition="all 0.9s ease-in-out">
              <HStack
                m={"1.25rem"}
                justifyContent={"space-between"}
                alignItems={"center"}
                fontSize={"1.25rem"}
                fontWeight={"700"}
              >
                <Box> Tickets total</Box>
                <Box>{i18n.t("free")}</Box>
              </HStack>
              <Box
                height={seeDetails ? "7.5rem" : "2.5rem"}
                transition="all .5s ease"
                borderBottom={"1px solid gray"}
                marginBottom={"1.5rem"}
                overflow={"hidden"}
              >
                {seeDetails && (
                  <Box
                    m={"1rem 1.25rem"}
                    pl={"12px"}
                    maxHeight={seeDetails ? "100%" : 0}
                    transition="all 0.5s ease"
                  >
                    <HStack
                      // textTransform={"capitalize"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        {_?.ticketSelected?.map((ticket, index) => (
                          <Text
                            marginTop={"0.37rem"}
                            className="gordita600"
                            fontSize={"1rem"}
                            fontWeight={400}
                            fontStyle={"normal"}
                            letterSpacing={"-0.01rem"}
                          >
                            {ticket.type} (x{ticket.quantity})
                          </Text>
                        ))}
                        {_?.perkSelected?.map((perk, index) => (
                          <Text
                            marginTop={"0.37rem"}
                            className="gordita600"
                            fontSize={"1rem"}
                            fontWeight={400}
                            fontStyle={"normal"}
                            letterSpacing={"-0.01rem"}
                          >
                            {perk.type} (x{perk?.quantity})
                          </Text>
                        ))}
                      </Box>
                      <Box textAlign={"right"}>
                        {_?.ticketSelected?.map((ticket, index) => (
                          <Text
                            color={"rgba(255, 255, 255, 0.60)"}
                            className="gordita600"
                            fontSize={"1rem"}
                            fontWeight={400}
                            letterSpacing={"-0.01rem"}
                          >
                            {i18n.t("free")}
                          </Text>
                        ))}
                        {_?.perkSelected?.map((perk, index) => (
                          <Text
                            color={"rgba(255, 255, 255, 0.60)"}
                            className="gordita600"
                            fontSize={"1rem"}
                            fontWeight={400}
                            letterSpacing={"-0.01rem"}
                          >
                            {i18n.t("free")}
                          </Text>
                        ))}
                      </Box>
                    </HStack>
                  </Box>
                )}
                <HStack
                  width={"8rem"}
                  transition="all 0.9s ease-in-out"
                  mx={"1.25rem"}
                  fontSize={"1rem"}
                  fontWeight={"700"}
                  textTransform={"capitalize"}
                  cursor={"pointer"}
                  onClick={() => setSeeDetails(!seeDetails)}
                >
                  <Box>{seeDetails ? `Hide` : `See`} Details</Box>
                  <Image
                    alt=""
                    src={seeDetails ? ArrowUpIcon : ArrowDownIcon}
                  />
                </HStack>
              </Box>

              <VStack width={"100%"} maxWidth={"90%"} margin={"1.5rem auto"}>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                  }}
                >
                  <>
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {i18n.t("firstName")}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={i18n.t("firstName")}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {i18n.t("lastName")}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder={i18n.t("lastName")}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                  </>

                  <div style={{ marginBottom: "20px", width: "100%" }}>
                    <label
                      htmlFor="name"
                      style={{
                        display: "block",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      {i18n.t("email")}
                      {/* Add an indicator for required field */}
                      <span style={{ color: "red", fontSize: "12px" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder={i18n.t("email")}
                      value={email}
                      pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        padding: "8px",
                        borderRadius: "6.25rem",
                        border: "1px solid #fff",
                        backgroundColor: _?.bgColor,
                        width: "100%",
                        height: "54px",
                      }}
                      required // Make the input field mandatory
                    />
                  </div>

                  {showInstagram && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Instagram Handle"}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="instagramHandle"
                        name="instagramHandle"
                        placeholder="Instagram handle without the '@'"
                        value={instagramHandle}
                        onChange={(e) => setInstagramHandle(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                  )}
                  {/*<div style={{ marginBottom: "20px", width: "100%" }}>
  <label
    htmlFor="WAnumber"
    style={{
      display: "block",
      marginLeft: "20px",
      marginBottom: "5px",
    }}
  >
    WhatsApp Phone Number
    <span style={{ color: "red", fontSize: "12px" }}>*</span>
  </label>
  <PhoneInput
    inputProps={{
      name: "WAnumber", // Specify the name for the input field
      placeholder: "Enter Your Phone Number",
      style: {
        width: "100%",
        border: "1px solid #FFFFFF",
        borderRadius: "6.25rem",
        height: "3.375rem",
        fontSize: "14px",
        color: "#FFFFFF",
        backgroundColor: "transparent",
      },
    }}
    style={{
      marginTop: "0.30rem",
    }}
    dropdownStyle={{ backgroundColor: "#000000" }}
    country={"us"} // Set your default country
    value={WAnumber} // Set the value explicitly
    onChange={handlePhoneNumberChange} 
    required // Make the input field mandatory
  />
</div>*/}

                  <Flex
                    {...(isMobile ? mobileButtonStyle : desktopButtonStyle)}
                  >
                    <Button
                      type="submit"
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.primary.default}
                      color={theme.buttons.primary.color}
                      _hover={{
                        bg: theme.buttons.primary.hover,
                        boxShadow: theme.buttons.primary.hoverBoxShadow,
                      }}
                      _active={{
                        boxShadow: theme.buttons.primary.activeBoxShadow,
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {i18n.t("getTickets")}
                    </Button>
                  </Flex>

                  {/* Error Message */}
                  {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                </form>
              </VStack>
            </Box>
          ) : (
            <HStack justifyContent="center" spacing={40}>
              <VStack width={"100%"} maxWidth={"20rem"}>
                <form
                  onSubmit={handleSubmit}
                  style={{
                    width: "100%",
                  }}
                >
                  <>
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {i18n.t("firstName")}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        placeholder={i18n.t("firstName")}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {i18n.t("lastName")}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder={i18n.t("lastName")}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                  </>
                  <div style={{ marginBottom: "20px", width: "100%" }}>
                    <label
                      htmlFor="name"
                      style={{
                        display: "block",
                        marginLeft: "20px",
                        marginBottom: "5px",
                      }}
                    >
                      {i18n.t("email")}
                      {/* Add an indicator for required field */}
                      <span style={{ color: "red", fontSize: "12px" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder={i18n.t("email")}
                      value={email}
                      pattern="(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+"
                      onChange={(e) => setEmail(e.target.value)}
                      style={{
                        padding: "8px",
                        borderRadius: "6.25rem",
                        border: "1px solid #fff",
                        backgroundColor: _?.bgColor,
                        width: "100%",
                        height: "54px",
                      }}
                      required // Make the input field mandatory
                    />
                  </div>
                  {showInstagram && (
                    <div style={{ marginBottom: "20px", width: "100%" }}>
                      <label
                        htmlFor="name"
                        style={{
                          display: "block",
                          marginLeft: "20px",
                          marginBottom: "5px",
                        }}
                      >
                        {"Instagram Handle"}
                        {/* Add an indicator for required field */}
                        <span style={{ color: "red", fontSize: "12px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="text"
                        id="instagramHandle"
                        name="instagramHandle"
                        placeholder="Instagram handle without the '@'"
                        value={instagramHandle}
                        onChange={(e) => setInstagramHandle(e.target.value)}
                        style={{
                          padding: "8px",
                          borderRadius: "6.25rem",
                          border: "1px solid #fff",
                          backgroundColor: _?.bgColor,
                          width: "100%",
                          height: "54px",
                        }}
                        required // Make the input field mandatory
                      />
                    </div>
                  )}
                  {/* <div style={{ marginBottom: "20px", width: "100%" }}>
  <label
    htmlFor="WAnumber"
    style={{
      display: "block",
      marginLeft: "20px",
      marginBottom: "5px",
    }}
  >
    WhatsApp Phone Number
    <span style={{ color: "red", fontSize: "12px" }}>*</span>
  </label>
  <PhoneInput
    inputProps={{
      name: "WAnumber", // Specify the name for the input field
      placeholder: "Enter Your Phone Number",
      style: {
        width: "100%",
        border: "1px solid #FFFFFF",
        borderRadius: "6.25rem",
        height: "3.375rem",
        fontSize: "14px",
        color: "#FFFFFF",
        backgroundColor: "transparent",
      },
    }}
    style={{
      marginTop: "0.30rem",
    }}
    dropdownStyle={{ backgroundColor: "#000000" }}
    country={"us"} // Set your default country
    value={WAnumber} // Set the value explicitly
    onChange={handlePhoneNumberChange} 
    required // Make the input field mandatory
  />
  </div>*/}

                  <Flex
                    {...(isMobile ? mobileButtonStyle : desktopButtonStyle)}
                  >
                    <Button
                      type="submit"
                      sx={theme.buttons.primary.shape}
                      bg={theme.buttons.primary.default}
                      color={theme.buttons.primary.color}
                      _hover={{
                        bg: theme.buttons.primary.hover,
                        boxShadow: theme.buttons.primary.hoverBoxShadow,
                      }}
                      _active={{
                        boxShadow: theme.buttons.primary.activeBoxShadow,
                      }}
                      style={{
                        width: "100%",
                      }}
                    >
                      {i18n.t("getTickets")}
                    </Button>
                  </Flex>

                  {/* Error Message */}
                  {errorMessage && (
                    <div style={{ color: "red" }}>{errorMessage}</div>
                  )}
                </form>
              </VStack>

              <Box w={"20rem"} bg={_?.bgColor}>
                <HStack
                  m={"2rem 1.25rem"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  fontSize={"1.25rem"}
                  fontWeight={"700"}
                >
                  <Box> Tickets total</Box>
                  <Box>{i18n.t("free")}</Box>
                </HStack>

                <Box
                  m={"1rem 1.25rem"}
                  maxHeight={seeDetails ? "100%" : 0}
                  transition="all 0.5s ease"
                >
                  <HStack
                    // textTransform={"capitalize"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Box>
                      {_?.ticketSelected?.map((ticket, index) => (
                        <Text
                          marginTop={"0.37rem"}
                          className="gordita600"
                          fontSize={"1rem"}
                          fontWeight={400}
                          fontStyle={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          {ticket.type} (x{ticket.quantity})
                        </Text>
                      ))}
                      {_?.perkSelected?.map((perk, index) => (
                        <Text
                          marginTop={"0.37rem"}
                          className="gordita600"
                          fontSize={"1rem"}
                          fontWeight={400}
                          fontStyle={"normal"}
                          letterSpacing={"-0.01rem"}
                        >
                          {perk.type} (x{perk?.quantity})
                        </Text>
                      ))}
                    </Box>
                    <Box textAlign={"right"}>
                      {_?.ticketSelected?.map((ticket, index) => (
                        <Text
                          color={"rgba(255, 255, 255, 0.60)"}
                          className="gordita600"
                          fontSize={"1rem"}
                          fontWeight={400}
                          letterSpacing={"-0.01rem"}
                        >
                          {i18n.t("free")}
                        </Text>
                      ))}
                      {_?.perkSelected?.map((perk, index) => (
                        <Text
                          color={"rgba(255, 255, 255, 0.60)"}
                          className="gordita600"
                          fontSize={"1rem"}
                          fontWeight={400}
                          letterSpacing={"-0.01rem"}
                        >
                          {i18n.t("free")}
                        </Text>
                      ))}
                    </Box>
                  </HStack>
                </Box>
              </Box>
            </HStack>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default withAuth(RSVP, [
  userRoles.ORGANIZER,
  userRoles.ADMIN,
  userRoles.ATTENDEE,
  userRoles.PREMIUM_ORGANIZER,
]);
