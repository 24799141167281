import {
  Box,
  Flex,
  HStack,
  Stack,
  Text,
  VStack,
  CircularProgress,
  CircularProgressLabel,
  useColorModeValue,
  Tabs,
  Tab,
  TabList,
  TabIndicator,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import secrets from "../../../secrets";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";
import { useRecoilState } from "recoil";
import { user } from "../../../recoil/atoms/user";
import { userRoles } from "../../../utils/constants";
import axios from "../../../utils/api/axios";
import "jspdf-autotable";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import getSymbolFromCurrency from "currency-symbol-map";


const getColor = (percentage) => {
  if (percentage <= 33) {
    return { fill: "#690DFF", back: "#C2B8FF" };
  } else if (percentage > 33 && percentage <= 65) {
    return { fill: "#FF5C00", back: "#FB8340" };
  } else {
    return { fill: "#FF5C00", back: "#C2FFED" };
  }
};
const analytics = [
  {
    name: "Total Events",
    quantity: "60",
    percentage: "+9.15%",
    type: "up",
  },
  {
    name: "Total Earnings",
    quantity: "$1156",
    percentage: "-0.56",
    type: "up",
  },
  {
    name: "Total Tickets",
    quantity: "354 ",
    percentage: "-11.01%",
    type: "up",
  },

  {
    name: "Total Perks",
    quantity: "23",
    percentage: "+1.48%",
    type: "up",
  },
  {
    name: "Total Likes",
    quantity: "23",
    percentage: "+1.48%",
    type: "up",
  },
  {
    name: "Event Page Views",
    quantity: "23",
    percentage: "+1.48%",
    type: "up",
  },
  {
    name: "Total Followers",
    quantity: "23",
    percentage: "-1.48%",
    type: "down",
  },
  {
    name: "Conversion Rate",
    quantity: "23",
    percentage: "+1.48%",
    type: "up",
  },
];

const Tabss = ({ name, color, setChartType }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Text
      fontSize={18}
      className="gordita700"
      cursor="pointer"
      onClick={() => setChartType(name)}
      color={color}
    ></Text>
  );
};
const Tabsss = ({ name, color, setChartType }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Text
      fontSize={18}
      className="gordita700"
      cursor="pointer"
      onClick={() => {}}
      color={color}
    ></Text>
  );
};

const Progress = ({ trackColor, max, value, colorScheme }) => {
  const percentage = (value / max) * 100;
  const color = getColor(percentage);

  return (
    <Box
      w="100%"
      h="8px"
      borderRadius="70px"
      zIndex={0}
      bg={trackColor}
      pos="relative"
      style={{
        margin: 4,
      }}
    >
      <Box
        borderRadius="70px"
        bg={colorScheme}
        left={0}
        top={0}
        w={`${percentage}%`}
        height="100%"
        zIndex={2}
        pos="absolute"
      ></Box>
    </Box>
  );
};

const getNumber = (value) => {
  const number = value ? Number(value) : 0;
  if (number) {
    return number ? (number / 100).toFixed(2) : number;
  }
  return 0;
};

const CVSHeaders = [
  { label: "Ticket Id", key: "ticketId" },
  { label: "Transaction Hash", key: "transactionHash" },
  { label: "Customer Name", key: "metadata.uName" },
  { label: "Order Date", key: "createdAt" },
  { label: "Sale Price", key: "totalPrice" },
];
const DashboardComponent = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [chartType, setChartType] = useState("Tickets");
  const colorValue = useColorModeValue("black.100", "white.100");
  const [selectedEvent, setSelectedEvent] = useState("allTime");
  const [hover, setHover] = useState("");
  const [dashboard, setDashboard] = useState({});
  const [_] = useRecoilState(user);
  const [csvData, setCsvData] = useState([]);
  const [pdfData, setPdfData] = useState(null);
  const [chartData, setChartData] = useState({});
  const [financeChartsData, setFinanceChartsData] = useState({});
  const [ticketChartType, setTicketChartType] = useState("FOURWEEKCharts");
  const [financeChartType, setFinanceChartType] = useState("FOURWEEKCharts");
  const [ticketChartData, setTicketChartData] = useState({});
  const [collectableChartData, setCollectableChartData] = useState({});
  const [eventsSold, setEventsSold] = useState([]);
  const [ticketsSold, setTicketsSold] = useState([]);
  const navigate = useNavigate();
  const [yAxisDomain, setYAxisDomain] = useState([0, "auto"]);
  const [yAxisDomain2, setYAxisDomain2] = useState([0, "auto"]);
  const [dataPoints, setDataPoints] = useState([]);
  const organizerId = _.userData?.organizerId;

  /**
   * This function retrieves finance chart data from an API endpoint and sets it to state.
   */
  const getFinanceChartData = async () => {
    let url = "/api/dashboard/admin/charts/finance";
    if (
      _?.userData?.userRole === userRoles.ORGANIZER ||
      _?.userData?.userRole === userRoles.PREMIUM_ORGANIZER
    ) {
      url = "/api/dashboard/organizer/charts/" + organizerId + "/finance";
    }
    await axios
      .get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      })
      .then((res) => {
        setFinanceChartsData(res.data);
        const data = res.data["FOURWEEKCharts"];
        const max = data.reduce(
          (maxValue, item) => Math.max(maxValue, item.total_price),
          0
        );
        setYAxisDomain2([0, max]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function retrieves ticket chart data from an API endpoint and sets it in the component state.
   */
  const getTicketsChartData = async () => {
    let url = "/api/dashboard/admin/charts/tickets";
    if (
      _?.userData?.userRole === userRoles.ORGANIZER ||
      _?.userData?.userRole === userRoles.PREMIUM_ORGANIZER
    ) {
      url = "/api/dashboard/organizer/charts/" + organizerId + "/tickets";
    }
    await axios
      .get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      })
      .then((res) => {
        setTicketChartData(res.data);
        const data = res.data["FOURWEEKCharts"];
        const max = data.reduce(
          (maxValue, item) => Math.max(maxValue, item.tickets),
          0
        );
        setYAxisDomain([0, max]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function retrieves collectables chart data from an API endpoint and sets it to state.
   */
  const getCollectablesChartData = async () => {
    let url = "/api/dashboard/admin/charts/collectables";
    if (
      _?.userData?.userRole === userRoles.ORGANIZER ||
      _?.userData?.userRole === userRoles.PREMIUM_ORGANIZER
    ) {
      url = "/api/dashboard/organizer/charts/" + organizerId + "/collectables";
    }
    await axios
      .get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      })
      .then((res) => {
        setCollectableChartData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * This function generates a PDF by fetching ticket data from an API endpoint and setting the data to
   * be used for the PDF.
   */
  const generatePdf = async () => {
    let url = "/api/getTickets/admin";
    if (
      _?.userData?.userRole === userRoles.ORGANIZER ||
      _?.userData?.userRole === userRoles.PREMIUM_ORGANIZER
    ) {
      url = "/api/getTickets/organizer/" + organizerId;
    }
    await axios
      .get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      })
      .then((res) => {
        setPdfData(res.data?.tickets);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * The function generates a CSV file by fetching ticket data from an API endpoint and setting the data
   * to a state variable.
   */
  const generateCSV = async () => {
    let url = "/api/getTickets/admin";
    if (
      _?.userData?.userRole === userRoles.ORGANIZER ||
      _?.userData?.userRole === userRoles.PREMIUM_ORGANIZER
    ) {
      url = "/api/getTickets/organizer/" + organizerId;
    }
    await axios
      .get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      })
      .then((res) => {
        setCsvData(res.data?.tickets);
        return;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
  };

  /**
   * This function retrieves data from an API endpoint and sets the retrieved data to various variables.
   */
  const init = async () => {
    const url =
      _.userData?.userRole === userRoles.ADMIN
        ? "/api/dashboard/admin"
        : "/api/dashboard/organizer/" + organizerId;
    axios
      .get(url, {
        headers: {
          "x-auth-token": _.token,
        },
      })
      .then((res) => {
        const data = res.data?.dashboard;
        setDataPoints(data);

        if (data) {
          if (!isEmpty(data.ticketsPercentage)) {
            setTicketsSold(data.ticketsPercentage);
          }
          if (!isEmpty(data.eventsSold)) {
            setEventsSold(data.eventsSold);
          }

          setDashboard({
            "Total Events": data.eventCount[0].event_count_all_time,
            "Total Earnings":
    getSymbolFromCurrency(data.currency[0].currency) + 
    (data.totalEarnings[0].total_price_all_time ? 
        Number(data.totalEarnings[0].total_price_all_time).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
        : '0.00'),

            "Total Tickets": data.totalTickets[0].nft_all_time,
            "Total Followers": data.followers[0].followers_all_time,
            "Total Likes": data.likes[0].likes_all_time,
            "Event Page Views": data.eventPageViews[0].view_count_all_time,
            "Total Perks": data.totalPerks[0].nft_all_time,
            "Conversion Rate":
              (
                (100 * data.totalTickets[0].nft_all_time) /
                data.eventPageViews[0].view_count_all_time
              ).toFixed(2) + "%",
          });
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    init();
    generatePdf();
    getCollectablesChartData();
    getTicketsChartData();
    getFinanceChartData();
  }, []);

  return (
    <Box w="100%">
      <Flex
        borderBottom={"1px solid gray"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"end"}
        mb={30}
        overflow={"scroll"}
        pos={"sticky"}
        minHeight={"80px"}
        top={0}
        bg={"rgba(0, 0, 0, 0.50)"}
        backdropFilter={"blur(150px)"}
        zIndex={10}
      >
        <Tabs
          style={{ overflow: "scroll" }}
          position="relative"
          variant="unstyled"
        >
          <TabList>
            <Tab
              padding={isMobile ? "0" : "0 10px"}
              ml={isMobile ? "30px" : "0"}
              fontWeight="700"
              fontSize={isMobile ? "12px" : "1rem"}
              onClick={() => {
                setSelectedEvent("allTime");
                setDashboard({
                  "Total Events": dataPoints.eventCount[0].event_count_all_time,
"Total Earnings": 
    getSymbolFromCurrency(dataPoints.currency[0].currency) + 
    (Number(dataPoints.totalEarnings[0].total_price_all_time) 
    ? Number(dataPoints.totalEarnings[0].total_price_all_time).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "0.00"),
    
                  "Total Tickets": dataPoints.totalTickets[0].nft_all_time,
                  "Total Perks": dataPoints.totalPerks[0].nft_all_time,
                  "Total Likes": dataPoints.likes[0].likes_all_time,
                  "Event Page Views":
                    dataPoints.eventPageViews[0].view_count_all_time,
                  "Total Followers": dataPoints.followers[0].followers_all_time,
                  "Conversion Rate":
                    (
                      (100 * dataPoints.totalTickets[0].nft_all_time) /
                      dataPoints.eventPageViews[0].view_count_all_time
                    ).toFixed(2) + "%",
                });
              }}
            >
              <span style={{ minWidth: "6rem" }}>All Time</span>
            </Tab>
            <Tab
              padding={isMobile ? "0" : "0 10px"}
              fontWeight="700"
              fontSize={isMobile ? "12px" : "1rem"}
              onClick={() => {
                setSelectedEvent("Today");
                setDashboard({
                  "Total Events": dataPoints.eventCount[0].event_count_today,
"Total Earnings": 
    getSymbolFromCurrency(dataPoints.currency[0].currency) + 
    (Number(dataPoints.totalEarnings[0].total_price_today) 
    ? Number(dataPoints.totalEarnings[0].total_price_today).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "0.00"),

                  "Total Tickets": dataPoints.totalTickets[0].nft_today,
                  "Total Followers": dataPoints.followers[0].followers_today,
                  "Total Likes": dataPoints.likes[0].likes_today,
                  "Event Page Views":
                    dataPoints.eventPageViews[0].view_count_today,
                  "Total Perks": dataPoints.totalPerks[0].nft_today,
                  "Conversion Rate":
                    (
                      (100 * dataPoints.totalTickets[0].nft_today) /
                      dataPoints.eventPageViews[0].view_count_today
                    ).toFixed(2) + "%",
                });
              }}
            >
              <span style={{ minWidth: "6rem" }}>Today</span>
            </Tab>
            <Tab
              padding={isMobile ? "0" : "0 10px"}
              fontWeight="700"
              fontSize={isMobile ? "12px" : "1rem"}
              onClick={() => {
                setSelectedEvent("Last Week");
                setDashboard({
                  "Total Events": dataPoints.eventCount[0].event_count_week,
"Total Earnings": 
    getSymbolFromCurrency(dataPoints.currency[0].currency) + 
    (Number(dataPoints.totalEarnings[0].total_price_week) 
    ? Number(dataPoints.totalEarnings[0].total_price_week).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "0.00"),
                  "Total Tickets": dataPoints.totalTickets[0].nft_week,
                  "Total Followers": dataPoints.followers[0].followers_week,
                  "Total Likes": dataPoints.likes[0].likes_week,
                  "Event Page Views":
                    dataPoints.eventPageViews[0].view_count_week,
                  "Total Perks": dataPoints.totalPerks[0].nft_week,
                  "Conversion Rate":
                    (
                      (100 * dataPoints.totalTickets[0].nft_week) /
                      dataPoints.eventPageViews[0].view_count_week
                    ).toFixed(2) + "%",
                });
              }}
            >
              <span style={{ minWidth: "6rem" }}>Last Week</span>
            </Tab>
            <Tab
              padding={isMobile ? "0" : "0 10px"}
              fontWeight="700"
              fontSize={isMobile ? "12px" : "1rem"}
              onClick={() => {
                setSelectedEvent("Last Month");
                setDashboard({
                  "Total Events": dataPoints.eventCount[0].event_count_month,
"Total Earnings": 
    getSymbolFromCurrency(dataPoints.currency[0].currency) + 
    (Number(dataPoints.totalEarnings[0].total_price_month) 
    ? Number(dataPoints.totalEarnings[0].total_price_month).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "0.00"),
                      
                  "Total Tickets": dataPoints.totalTickets[0].nft_month,
                  "Total Followers": dataPoints.followers[0].followers_month,
                  "Total Likes": dataPoints.likes[0].likes_month,
                  "Event Page Views":
                    dataPoints.eventPageViews[0].view_count_month,
                  "Total Perks": dataPoints.totalPerks[0].nft_month,
                  "Conversion Rate":
                    (
                      (100 * dataPoints.totalTickets[0].nft_month) /
                      dataPoints.eventPageViews[0].view_count_month
                    ).toFixed(2) + "%",
                });
              }}
            >
              <span style={{ minWidth: "6rem" }}>Last Month</span>
            </Tab>
            <Tab
              padding={isMobile ? "0" : "0 10px"}
              fontWeight="700"
              fontSize={isMobile ? "12px" : "1rem"}
              onClick={() => {
                setSelectedEvent("yearToDate");
                setDashboard({
                  "Total Events":
                    dataPoints.eventCount[0].event_count_year_to_date,
"Total Earnings": 
    getSymbolFromCurrency(dataPoints.currency[0].currency) + 
    (Number(dataPoints.totalEarnings[0].total_price_year_to_date) 
    ? Number(dataPoints.totalEarnings[0].total_price_year_to_date).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) 
    : "0.00"),
                     
                  "Total Tickets": dataPoints.totalTickets[0].nft_year_to_date,
                  "Total Followers":
                    dataPoints.followers[0].followers_year_to_date,
                  "Total Likes": dataPoints.likes[0].likes_year_to_date,
                  "Event Page Views":
                    dataPoints.eventPageViews[0].view_count_year_to_date,
                  "Total Perks": dataPoints.totalPerks[0].nft_year_to_date,
                  "Conversion Rate":
                    (
                      (100 * dataPoints.totalTickets[0].nft_year_to_date) /
                      dataPoints.eventPageViews[0].view_count_year_to_date
                    ).toFixed(2) + "%",
                });
              }}
            >
              <span style={{ minWidth: "6rem" }}>Year To Date</span>
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="white"
            borderRadius="1px"
            w={"10px"}
          />
        </Tabs>
      </Flex>

      <div style={{ padding: "0 30px 30px 30px" }}>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          color="white.100"
          mt={12}
          w="100%"
        >
          {analytics.map(({ name, percentage, quantity, type }, index) => (
            <Box
              key={index}
              mb={10}
              className="gordita400"
              p={24}
              bg="#000000"
              borderRadius="10px"
              borderColor="rgba(255, 255, 255, 0.20)"
              borderWidth="1px"
              flexBasis={isMobile ? "100%" : "24%"}
            >
              <Text className="gordita700" fontSize={"1rem"}>
                {name}
              </Text>
              <HStack
                mt={12}
                justifyContent="s pace-between"
                alignItems="flex-end"
                color="primary.100"
              >
                <Text fontSize={24} className="gordita700">
                  {dashboard[name] || 0}
                </Text>
              </HStack>
            </Box>
          ))}
        </Flex>

        <Box
          gap={18}
          display="flex"
          flexDir={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-start" : "center"}
        >
          <VStack
            width={"100%"}
            justifyContent="center"
            flexBasis="50%"
            alignItems="flex-start"
          >
            <HStack alignItems="center" justifyContent="center" mt={20}>
              <Tabss
                color={chartType === "Tickets" ? "primary.100" : colorValue}
                setChartType={setChartType}
                name={"Tickets"}
              />
            </HStack>
            <Box
              w="100%"
              color="white.100"
              px={"1.25rem"}
              py={"1.25rem"}
              bg="#000000"
              borderRadius="10px"
              borderColor="rgba(255, 255, 255, 0.20)"
              borderWidth="1px"
              height={isMobile ? "22rem" : "20.625rem"}
            >
              <HStack
                flexDir={isMobile ? "column" : "row"}
                justifyContent="space-between"
                mb={12}
              >
                <Text className="gordita700" fontSize={14}>
                  Tickets Sold
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={0}
                >
                  <Text
                    bg={
                      ticketChartType === "oneWeekCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("oneWeekCharts");
                      const data = ticketChartData["oneWeekCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={ticketChartType === "oneWeekCharts" ? "#FF5C00" : ""}
                  >
                    1W
                  </Text>
                  <Text
                    bg={
                      ticketChartType === "FOURWEEKCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("FOURWEEKCharts");
                      const data = ticketChartData["FOURWEEKCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={
                      ticketChartType === "FOURWEEKCharts" ? "#FF5C00" : ""
                    }
                  >
                    4W
                  </Text>
                  <Text
                    bg={
                      ticketChartType === "oneYearCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("oneYearCharts");
                      const data = ticketChartData["oneYearCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={ticketChartType === "oneYearCharts" ? "#FF5C00" : ""}
                  >
                    1Y
                  </Text>
                  <Text
                    bg={
                      ticketChartType === "mtdCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("mtdCharts");
                      const data = ticketChartData["mtdCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={ticketChartType === "mtdCharts" ? "#FF5C00" : ""}
                  >
                    MTD
                  </Text>
                  <Text
                    bg={
                      ticketChartType === "ytdCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("ytdCharts");
                      const data = ticketChartData["ytdCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={ticketChartType === "ytdCharts" ? "#FF5C00" : ""}
                  >
                    YTD
                  </Text>
                  <Text
                    bg={
                      ticketChartType === "allTimeCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    cursor="pointer"
                    onClick={() => {
                      setTicketChartType("allTimeCharts");
                      const data = ticketChartData["allTimeCharts"];
                      const max = data.reduce(
                        (maxValue, item) => Math.max(maxValue, item.tickets),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain([0, max]);
                    }}
                    color={ticketChartType === "allTimeCharts" ? "#FF5C00" : ""}
                  >
                    ALL
                  </Text>
                </Box>
              </HStack>
              <ResponsiveContainer width="98%" height={300}>
                <LineChart
                  width={500}
                  height={300}
                  data={
                    chartType === "Tickets"
                      ? !isEmpty(ticketChartData)
                        ? ticketChartData[ticketChartType]
                        : []
                      : !isEmpty(collectableChartData)
                      ? collectableChartData[ticketChartType]
                      : []
                  }
                  margin={{
                    top: 20,
                    right: 0,
                    left: -35,
                    bottom: 50,
                  }}
                >
                  <CartesianGrid
                    horizontal={true}
                    vertical=""
                    stroke="#243240"
                  />
                  <XAxis
                    padding={{ left: 30, right: 30 }}
                    dataKey="name"
                    tick={{
                      fill: "#fff",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                    }}
                  />
                  <YAxis
                    stroke="undefined"
                    domain={[yAxisDomain[0], yAxisDomain[1] + 2]}
                    dataKey="tickets"
                    tick={{
                      fill: "#fff",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                    }}
                    lang="M"
                  />

                  <Tooltip
                    contentStyle={{ backgroundColor: "#000000", color: "#fff" }}
                    itemStyle={{ color: "#fff" }}
                    cursor={false}
                  />
                  <Line
                    name={chartType}
                    type="monotone"
                    dataKey="tickets"
                    stroke="#FF5C00"
                    strokeWidth="2"
                    dot={{
                      fill: "#FF5C00",
                      stroke: "#FF5C00",
                      strokeWidth: 2,
                      r: 5,
                    }}
                    activeDot={{
                      fill: "#FF5C00",
                      stroke: "#FF5C00",
                      strokeWidth: 5,
                      r: 10,
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </VStack>
          <VStack
            width={"100%"}
            justifyContent="center"
            flexBasis="50%"
            alignItems="flex-start"
          >
            <HStack mt={20}>
              <Tabss color={"primary.100"} name={"Finance"} />
            </HStack>
            <Box
              w="100%"
              color="white.100"
              px={"1.25rem"}
              py={"1.25rem"}
              bg="#000000"
              borderRadius="10px"
              borderColor="rgba(255, 255, 255, 0.20)"
              borderWidth="1px"
              height={isMobile ? "22rem" : "20.625rem"}
            >
              <HStack
                flexDir={isMobile ? "column" : "row"}
                alignItems={"center"}
                justifyContent="space-between"
                mb={12}
              >
                <Text className="gordita700" fontSize={14}>
                  Net Sales
                </Text>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={0}
                >
                  <Text
                    bg={
                      financeChartType === "oneWeekCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("oneWeekCharts");
                      const data = financeChartType["oneWeekCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={
                      financeChartType === "oneWeekCharts" ? "#FF5C00" : ""
                    }
                  >
                    1W
                  </Text>
                  <Text
                    bg={
                      financeChartType === "FOURWEEKCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("FOURWEEKCharts");
                      const data = financeChartType["FOURWEEKCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={
                      financeChartType === "FOURWEEKCharts" ? "#FF5C00" : ""
                    }
                  >
                    4W
                  </Text>
                  <Text
                    bg={
                      financeChartType === "oneYearCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("oneYearCharts");
                      const data = financeChartType["oneYearCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={
                      financeChartType === "oneYearCharts" ? "#FF5C00" : ""
                    }
                  >
                    1Y
                  </Text>
                  <Text
                    bg={
                      financeChartType === "mtdCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("mtdCharts");
                      const data = financeChartType["mtdCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={financeChartType === "mtdCharts" ? "#FF5C00" : ""}
                  >
                    MTD
                  </Text>
                  <Text
                    bg={
                      financeChartType === "ytdCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    letterSpacing={"-0.01rem"}
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("ytdCharts");
                      const data = financeChartType["ytdCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={financeChartType === "ytdCharts" ? "#FF5C00" : ""}
                  >
                    YTD
                  </Text>
                  <Text
                    bg={
                      financeChartType === "allTimeCharts"
                        ? "rgba(255, 92, 0, 0.1)"
                        : ""
                    }
                    borderColor="#FF5C00"
                    className="gordita400"
                    borderRadius="6.25rem"
                    p={"10px 15px"}
                    fontSize={"14"}
                    fontWeight="700"
                    cursor="pointer"
                    onClick={() => {
                      setFinanceChartType("allTimeCharts");
                      const data = financeChartType["allTimeCharts"];
                      const max = data.reduce(
                        (maxValue, item) =>
                          Math.max(maxValue, item.total_price),
                        0
                      );

                      // Set the Y-axis domain with the maximum value
                      setYAxisDomain2([0, max]);
                    }}
                    color={
                      financeChartType === "allTimeCharts" ? "#FF5C00" : ""
                    }
                  >
                    ALL
                  </Text>
                </Box>
              </HStack>
              <ResponsiveContainer width="98%" height="100%">
                <BarChart
                  width={"100%"}
                  height={"100%"}
                  data={
                    !isEmpty(financeChartsData)
                      ? financeChartsData[financeChartType]
                      : []
                  }
                  margin={{
                    top: 0,
                    right: 0,
                    left: -15,
                    bottom: 50,
                  }}
                >
                  <XAxis
                    tick={{
                      fill: "#fff",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                    }}
                    dataKey="name"
                  />
                  <YAxis
                    stroke="undefined"
                    tick={{
                      fill: "#fff",
                      fontSize: "0.75rem",
                      fontWeight: "400",
                    }}
                  />
                  <Tooltip
                    cursor={false}
                    formatter={(value) => `$${value.toFixed(2)}`} // Format the value as currency with 2 decimal places
                  />
                  <Bar
                    name="Net Sales"
                    barSize={4}
                    dataKey="total_price"
                    fill="#FF5C00"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </VStack>
        </Box>

        <Box
          mt={"2.5rem"}
          bg="#000000"
          borderColor="rgba(255, 255, 255, 0.20)"
          borderWidth="1px"
          borderRadius="0.625rem"
          p="1.25rem"
        >
          <HStack alignItems="center">
            <HStack flex={1}>
              <Text
                flex={2}
                fontSize={18}
                color={colorValue}
                className="gordita700"
              >
                Total Tickets Sold
              </Text>
            </HStack>
            {/*<HStack alignItems="center" justifyContent="flex-end" flex={1}>
              <HStack justifyContent="center" alignItems="center" spacing={0}>
                <PDFDownloadLink
                  document={<MyDocument data={pdfData} />}
                  fileName="my_table.pdf"
                >
                  <Image pl={16} src={pdf} />
                </PDFDownloadLink>
                <CSVLink
                  asyncOnClick={true}
                  onClick={(event, done) => {
                    generateCSV();
                  }}
                  data={csvData}
                  headers={CVSHeaders}
                  filename={"my-data.csv"}
                >
                  <Image pl={16} src={xls} />
                </CSVLink>
              </HStack>
            </HStack>*/}
          </HStack>
          <HStack
            gap={15}
            m="1rem"
            mt={"1.3rem"}
            flexDir={isMobile ? "column" : "row"}
          >
            <Box bg="#000000" p={0} m={0} borderRadius="100%">
              {!isEmpty(ticketsSold) && !isEmpty(ticketsSold[0]) && (
                <CircularProgress
                  p={0}
                  m={0}
                  value={getNumber(ticketsSold[0].total_sold)}
                  max={getNumber(ticketsSold[0].total_available)}
                  color="#FF5C00"
                  ringColor="#FF5C00"
                  size="12rem"
                  trackColor="rgba(255, 255, 255, 0.1)"
                  thickness="6px"
                  capIsRound={true}
                >
                  <CircularProgressLabel>
                    <VStack spacing={0} gap={0}>
                      <Text
                        fontSize={16}
                        className="gordita600"
                        color="#FFFFFF"
                      >
                        {Number(ticketsSold[0].sold_percentage || 0).toFixed(2)}
                        %
                      </Text>
                      <Text
                        fontSize={12}
                        className="gordita400"
                        color="#FFFFFF"
                      >
                        {ticketsSold[0].total_sold}/
                        {ticketsSold[0].total_available}
                      </Text>
                    </VStack>
                  </CircularProgressLabel>
                </CircularProgress>
              )}
            </Box>
            <Stack
              flexWrap="wrap"
              alignItems="flex-end"
              flexDir="row"
              gap="5%"
              flex={1}
            >
              {eventsSold.length > 0 &&
                eventsSold.map((event, index) => {
                  return (
                    <VStack
                      key={index}
                      gap={0}
                      flexBasis={isMobile ? "100%" : "30%"}
                      // width={"30%"}
                      height={"3rem"}
                    >
                      <HStack w="100%" justifyContent="space-between">
                        <Text
                          color="white.100"
                          fontSize={"1rem"}
                          fontWeight={400}
                          width="100%"
                          maxW={"190px"}
                          overflow={"hidden"}
                          whiteSpace={"nowrap"}
                          textOverflow={"ellipsis"}
                        >
                          {event.eventName}
                        </Text>
                        <Text
                          color="white.100"
                          fontSize={"1rem"}
                          fontWeight={700}
                        >
                          {
                            // Calculate the percentage and handle NaN case
                            isNaN(
                              (Number(event.totalTickets || 0) -
                                Number(event.available || 0)) /
                                Number(event.totalTickets)
                            )
                              ? 0
                              : (
                                  ((Number(event.totalTickets || 0) -
                                    Number(event.available || 0)) /
                                    Number(event.totalTickets)) *
                                  100
                                ).toFixed(2)
                          }
                          %
                        </Text>
                      </HStack>
                      <Progress
                        max={Number(event.totalTickets)}
                        value={
                          Number(event.totalTickets || 0) -
                          Number(event.available || 0)
                        }
                        colorScheme="#FF5C00"
                        trackColor="rgba(255, 255, 255, 0.1)"
                      />
                    </VStack>
                  );
                })}
            </Stack>
          </HStack>
        </Box>
      </div>
    </Box>
  );
};

export default DashboardComponent;
