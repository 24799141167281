import {
  Box,
  HStack,
  useColorModeValue,
  VStack,
  useToast,
  Spinner,
  Text,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Collapse,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';

import { useEffect, useState, useRef } from 'react';
import { theme } from '../../../../styles/theme/base';
import { Formik } from 'formik';
import BasicEvents from './BasicEvents';
import { EventBar } from './SharedModules';
import TicktDetails from './TicketDetails';
import ArtistLineup from './Artist';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Promotion from './Promotion';
import Summary from './Summary';
import { postEvent, modifyEvent } from '../../../../utils/actions/event';
import NFTMeta from './NFTMetaData';
import { createEventSchema, updateEventSchema } from '../../../../utils/schema';
import { BsArrowRight, BsChevronLeft } from 'react-icons/bs';
import GuestList from './GuestList';
import Music from './Music';
import Trailer from './Trailer';
import PromoCodes from './PromoCodes';
import { getEventsByUUID } from '../../../../utils/actions/event';
import { isEmpty, values } from 'lodash';
import Admin from './Admin';
import ExtraPictures from './ExtraPictures';
import EventPassword from './EventPassword';
import Perks from './Perks';
import TableDetails from './TableDetails';
import Tags from './Tags';
import Language from './Language';
import { useRecoilState } from 'recoil';
import SignUpOrganizer from '../../SignUpOrganizer';
import SignUp from '../../SignUp';
import {
  RegistraionModalTypes,
  registration,
} from '../../../../recoil/atoms/registration';
import { user } from '../../../../recoil/atoms/user';

import { useMediaQuery } from '../../../../utils/useMediaQuery';
import LoginByEmail from '../../LoginByEmail';
import LoginVerificationByEmail from '../../LoginByEmailVerification';
import SignUpVerification from '../../SignUpVerification';
import LoginVerification from '../../LoginVerification';

const getTotalTickets = (types) => {
  let total = 0;

  if (Array.isArray(types)) {
    for (const type of types) {
      if (type.ticketWaves && Array.isArray(type.ticketWaves)) {
        for (const wave of type.ticketWaves) {
          if (wave.quantity) {
            const quantity = parseFloat(wave.quantity);
            if (!isNaN(quantity)) {
              total += quantity;
            }
          }
        }
      }
    }
  }

  return total;
};

const getTotalTables = (waves) => {
  let total = 0;

  if (Array.isArray(waves)) {
    for (const wave of waves) {
      if (wave.quantity) {
        const quantity = parseFloat(wave.quantity);
        if (!isNaN(quantity)) {
          total += quantity;
        }
      }
    }
  }

  return total;
};

/**
 * The CreateEvent function is a React component that renders a form for creating an event and handles
 * the submission of the form data.
 * @returns The `CreateEvent` component is being returned.
 */
const CreateEvent = ({ login, provider, paySolFee }) => {
  const params = useParams();
  const { id } = params;
  const location = useLocation();
  const { isEditMode } = location.state || {};
  const formikRef = useRef(); // reference to formik handleSubmit func, since the submit button is outside the Formik component (in the header)
  const shouldShowContent = location.pathname.includes('/edit');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const toggleAdvanced = () => setShowAdvanced(!showAdvanced);
  const [step, setStep] = useState(1);
  const [event, setEvent] = useState();
  const [slug, setSlug] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setRegistrationModal] = useRecoilState(registration);
  const navigate = useNavigate();
  const toast = useToast();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [_U, setUser] = useRecoilState(user);
  const organizerId = _U.userData?.organizerId || _U.userData?.userId;

  const textValue = useColorModeValue(
    theme.colors.black[100],
    theme.colors.white[100]
  );
  const renderModal = () => {
    switch (_.modalType) {
      case RegistraionModalTypes.SIGNUPORGANIZER:
        return <SignUpOrganizer />;
      case RegistraionModalTypes.SIGNUP:
        return <SignUp />;
      case RegistraionModalTypes.LOGIN_EMAIL:
        return <LoginByEmail />;
      case RegistraionModalTypes.LOGIN_VERIFICATION_EMAIL:
        return <LoginVerificationByEmail />;
      case RegistraionModalTypes.SIGNUP_VERIFICATION:
        return <SignUpVerification />;
      case RegistraionModalTypes.LOGIN_VERIFICATION:
        return <LoginVerification />;
    }
  };
  const initialValues = {
    eventName: '',
    eventType: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    location: '',
    hideLocation: '',
    description: '',
    ticketName: '',
    ticketQuantity: '',
    ticketType: '',
    ticketPrice: '',
    asignCollectables: true,
    allowTransfer: false,
    allowResale: false,
    minimumResale: '',
    maximumResale: '',
    lineup: {},
    track: {},
    trailer: '',
    coverPicture: '',
    eventImage: '',
    ticketImage: '',
    currency: 'USD',
    ticketWaves: [],
    // tableTypes: [],
    ticketTypes: [
      {
        ticketTypeName: '',
        ticketWaves: [],
      },
    ],
    perkLists: [
      {
        perkListName: '',
        perks: [],
      },
    ],
    extraPictures: '',
    eventPassword: '',
    language: '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const init = async () => {
      if (id) {
        try {
          const val = await getEventsByUUID(id);
          if (!isEmpty(val)) {
            console.log('eeeeeee', val);
            setEvent(val);
            initialValues.eventName = val.eventName;
            initialValues.eventType = JSON.parse(val.eventType);
            initialValues.startDate = val.startDate;
            initialValues.endDate = val.endDate;
            initialValues.startTime = val.startTime;
            initialValues.endTime = val.endTime;
            initialValues.location = val.location;
            initialValues.description = val.description;
            initialValues.ticketImage = val.ticketImage;
            initialValues.longitude = val.longitude;
            initialValues.latitude = val.latitude;
            initialValues.city = val.city;
            initialValues.country = val.country;
            // Assuming val.ticketTypes is your original array
            // Assuming val.ticketTypes is an array of ticket objects
            initialValues.ticketTypes = val.ticketTypes?.reduce(
              (accumulator, ticket) => {
                const existingType = accumulator.find(
                  (type) => type.ticketTypeName === ticket.typeName
                );
                if (ticket.ticketEntries === 1) {
                  ticket.ticketEntries = '';
                }
                if (ticket.limitQuantity === 6) {
                  ticket.limitQuantity = '';
                }
                if (existingType) {
                  // If the ticketTypeName already exists, add the ticket to its ticketWaves array
                  existingType.ticketWaves.push({
                    name: ticket.ticketName,
                    description: ticket.ticketDescription,
                    quantity: ticket.ticketQuantity,
                    price: ticket.ticketPrice,
                    ticketId: ticket.ticketId,
                    ticketRemainingSupply: ticket.ticketRemainingSupply,
                    entries: ticket.ticketEntries,
                    limit: ticket.limitQuantity,
                    instagram: ticket.instagram,
                    start: ticket.start,
                    end: ticket.end,
                  });
                } else {
                  // If the ticketTypeName doesn't exist, create a new entry
                  accumulator.push({
                    ticketTypeName: ticket.typeName,
                    ticketWaves: [
                      {
                        name: ticket.ticketName,
                        description: ticket.ticketDescription,
                        quantity: ticket.ticketQuantity,
                        price: ticket.ticketPrice,
                        ticketId: ticket.ticketId,
                        ticketRemainingSupply: ticket.ticketRemainingSupply,
                        entries: ticket.ticketEntries,
                        limit: ticket.limitQuantity,
                        instagram: ticket.instagram,
                        start: ticket.start,
                        end: ticket.end,
                      },
                    ],
                  });
                }

                return accumulator;
              },
              []
            );

            // initialValues.tableTypes = val.tableTypes?.map((table) => ({
            //   name: table.tableName,
            //   description: table.tableDescription,
            //   quantity: table.tableQuantity,
            //   price: table.tablePrice,
            //   fullPrice: table.tableFullPrice,
            //   tableId: table.tableId,
            //   tableRemainingSupply: table.tableRemainingSupply,
            //   minPerson: table.minPerson,
            //   maxPerson: table.maxPerson,
            //   chargePP: table.chargePP,
            // }));
            initialValues.lineup = val.lineup;
            initialValues.currency = val.currency;
            initialValues.track = val.track;
            initialValues.coverPicture = val.eventImage;
            initialValues.eventImage = val.eventImage;
            initialValues.trailer = val.trailer;
            initialValues.tableLink = val.tableLink;
            initialValues.tableDescription = val.tableDescription;
            initialValues.guestList = val.guestList;
            initialValues.showGuestList = val.showGuestList;
            initialValues.spotifyId = val.spotifyId;
            initialValues.fee_percentage = val.fee_percentage;
            initialValues.fee_fixed = val.fee_fixed;
            initialValues.participant_fee_percentage =
              val.participant_fee_percentage;
            initialValues.participant_fee_fixed = val.participant_fee_fixed;
            initialValues.extraPictures = val.extraPictures;
            initialValues.eventPassword = val.eventPassword;
            initialValues.perkLists = val.perkLists.map((perkList) => ({
              ...perkList,
              perks: perkList.perks.map((perk) => ({
                name: perk.perkName,
                description: perk.perkDescription,
                image: perk.perkImage,
                perkId: perk.perkId,
                price: perk.perkPrice,
                quantity: perk.perkQuantity,
                perkRemainingSupply: perk.perkRemainingSupply,
              })),
            }));
            initialValues.language = val.language;

            setSlug(val.slug);
          }
        } catch (error) {
          console.log({ error });
        }
      }
    };
    if (id && isEditMode) {
      init();
    } else {
      console.log('No id, resetting form');
      setEvent();
      formikRef.current?.resetForm({ values: initialValues });
    }
  }, [id]);
  const token = localStorage.getItem('x-auth-token');

  /**
   * This is a function that handles the creation of an event, including validation and posting to a
   * server.
   * @param values - `values` is an object containing the data for creating a new event. It includes
   * properties such as `eventName`, `eventDescription`, `eventDate`, `eventTime`, `eventLocation`,
   * `regular`, `earlyBird`, `late`, `ticketQuantity`, `earlyBirdTicketQuantity`, `
   */
  const handleCreateEvent = async (values) => {
    let account = '';
    const provider = localStorage.getItem('provider');
    if (provider) {
      account = JSON.parse(provider)?.namespaces?.eip155?.accounts;
    }
    // setLoader(false);
    values.organizerId = organizerId;
    values.supply = Number(values?.ticketQuantity || 0);
    const totalTickets = getTotalTickets(values.ticketTypes);
    values.totalTickets = totalTickets;
    values.creatorAddress = account[0]?.split(':')?.pop();
    createEventSchema
      .validate({
        ...values,
      })
      .then(async () => {
        setIsLoading(true);
        try {
          await postEvent(
            {
              ...values,
            },
            token
          )
            .then((ne) => {
              localStorage.removeItem('event');
              if (ne.status) {
                toast({
                  title: 'Event Created Succesfully',
                  status: 'success',
                  isClosable: true,
                  duration: 4000,
                  position: 'bottom',
                });
                navigate('/events');
              } else {
                console.log('uuuuu', ne?.errors[0]?.message);

                toast({
                  title: 'Event Creation',
                  description: ne?.errors[0]?.message,
                  status: 'error',
                  isClosable: true,
                  duration: 8000,
                  position: 'bottom',
                });
              }
            })
            .catch((err) => {
              console.log('bbbb', { err });
              console.log('vvvvv', values.organizerId);
              toast({
                title: 'Event Create',
                description: err.message,
                status: 'error',
                isClosable: true,
                duration: 8000,
                position: 'bottom',
              });
            });
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
          toast({
            title: 'Event Create',
            description: err.message,
            status: 'error',
            isClosable: true,
            duration: 8000,
            position: 'bottom',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: 'Missing Field',
          description: error.message,
          status: 'error',
          isClosable: true,
          duration: 8000,
          position: 'bottom',
        });
        setIsLoading(false);
      });
  };

  const handleEditEvent = async (values) => {
    values.organizerId = organizerId;
    values.supply = Number(values?.ticketQuantity || 0);
    const totalTickets = getTotalTickets(values.ticketTypes);
    values.totalTickets = totalTickets;
    try {
      setIsLoading(true);
      await createEventSchema.validate({
        ...values,
      });
      await modifyEvent(
        {
          ...values,
          uuid: id,
        },
        token
      )
        .then((ne) => {
          if (ne?.status) {
            toast({
              title: 'Event Modified Successfully',
              status: 'success',
              isClosable: true,
              duration: 4000,
              position: 'bottom',
            });
            navigate('/events');
          } else {
            console.log('aaaa', ne?.errors[0]?.message);
            toast({
              title: 'Event Creation',
              description: ne?.errors[0]?.message,
              status: 'error',
              isClosable: true,
              duration: 4000,
              position: 'bottom',
            });
          }
        })
        .catch((err) => {
          console.log('bbbb', { err });
          toast({
            title: 'Event Create',
            description: err.message,
            status: 'error',
            isClosable: true,
            duration: 4000,
            position: 'bottom',
          });
        });
      setIsLoading(false);
    } catch (err) {
      console.log({ err });
      setIsLoading(false);
      toast({
        title: 'Missing Field',
        description: err.message,
        status: 'error',
        isClosable: true,
        duration: 4000,
        position: 'bottom',
      });
    }
  };

  const onStepChange = () => {
    if (step <= 5) setStep(step + 1);
  };

  const onProgressChange = (st) => {
    setStep(st + 1);
  };

  const onGoBack = () => {
    if (step < 2) {
      navigate(-1);
    } else setStep(step - 1);
  };

  const storedData = localStorage.getItem('event');
  const storageValue = storedData ? JSON.parse(storedData) : null;
  const [showSummary, setShowSummary] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handlePreviewSummary = () => {
    if (shouldShowContent) {
      navigate(`/event/${slug}`);
    } else {
      alert(
        'Fill in the required fields and save event as draft. You will then be able to see how your event looks like '
      );
    }
  };

  const handleSubmit = () => {
    if (formikRef.current) {
      // Manually call Formik's handleSubmit
      formikRef.current.handleSubmit();
    }
  };

  /* This is a React component called `CreateEvent` that renders a form for creating an event. It uses
  Chakra UI components such as `Box`, `HStack`, `VStack`, `Spinner`, and `useColorModeValue` to
  style the form. It also uses `useEffect` and `useState` hooks to manage the state of the
  component. */
  return (
    <Box
      style={{
        width: '100%',
        height: '100vh',
        overflow: 'scroll',
      }}
    >
      <Heading
        color="#FBFAFB"
        fontSize="2rem"
        pos={'sticky'}
        top={0}
        zIndex={40}
        w="100%"
        fontWeight={700}
        mb={'3.12rem'}
        bg={'rgba(0, 0, 0, 0.50)'}
        backdropFilter={'blur(150px)'}
        borderBottom={'1px solid rgba(255, 255, 255, 0.10)'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Box
          maxW={'1200px'}
          width={'100%'}
          display={'flex'}
          justifyContent={'space-between'}
          flexWrap={'wrap'}
          alignItems={'center'}
          margin={'0 auto'}
          padding={'1.75rem 30px'}
          gap={'10px'}
        >
          <Box w={'fit-content'} display={'flex'}>
            <Box>{shouldShowContent ? 'Edit Event' : 'New Event'}</Box>
          </Box>

          <Box display="flex" width={'fit-content'}>
            {shouldShowContent && (
              <Button
                sx={theme.buttons.primary.shape}
                bg={theme.buttons.secondary.border}
                color={theme.buttons.secondary.color}
                border={theme.buttons.secondary.border}
                _hover={{
                  borderColor: theme.buttons.secondary.hoverBorderColor,
                }}
                onClick={handlePreviewSummary}
                disabled={!shouldShowContent}
                style={{ width: 'fit-content', padding: '0 1.5rem' }}
                className="gordita700"
                mr={3}
              >
                Preview
              </Button>
            )}
            <Button
              isLoading={isLoading}
              loadingText={id && isEditMode ? 'Updating' : 'Saving'}
              className="gordita700"
              onClick={handleSubmit}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              style={{ width: 'fit-content', padding: '0 1.5rem' }}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              disabled={isButtonDisabled}
            >
              {id && isEditMode ? `Update Event` : `Save As Draft`}
            </Button>
          </Box>
        </Box>
      </Heading>
      <Box
        maxW={'39.625rem;'}
        margin={'0 auto'}
        p={'0 20px'}
        marginBottom={'80px'}
      >
        <Formik
          // initialValues={storageValue ? storageValue : initialValues}
          // validate={validate}
          initialValues={initialValues}
          //validationSchema={createEventSchema}
          onSubmit={(values) => {
            if (
              !_U?.userData?.userId ||
              _U?.userData?.userRole === 'ATTENDEE'
            ) {
              setRegistrationModal((lp) => {
                return {
                  ...lp,
                  openModal: true,
                  modalType: RegistraionModalTypes.SIGNUPORGANIZER,
                };
              });
            } else {
              if (id && isEditMode) {
                handleEditEvent(values);
              } else {
                handleCreateEvent(values);
              }
            }
          }}
          innerRef={formikRef}
        >
          {(formik) => {
            const {
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              resetForm,
              setFieldValue,
            } = formik;

            useEffect(() => {
              const requiredFields = [
                values.eventName,
                // values.eventType,
                // values.ticketTypes,
                // values.description,
                values.endDate,
                values.eventImage,
                values.location,
                values.startDate,
                values.startTime,
              ];

              const isAnyFieldEmpty = requiredFields.some((field) => !field);

              setIsButtonDisabled(isAnyFieldEmpty);
            }, [
              values.eventName,
              values.eventType,
              values.ticketTypes,
              values.description,
              values.endDate,
              values.eventImage,
              values.location,
              values.startDate,
              values.startTime,
            ]);

            return (
              <VStack
                w="100%"
                gap={'2rem'}
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Promotion
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />

                <BasicEvents
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />
                <Box className="div-separator" />

                {/* <NFTMeta
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />*/}

                <TicktDetails
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    isEditMode,
                  }}
                />
                <Box className="div-separator" />
                <Tags
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />
                <Box className="div-separator" />

                <Music
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />

                <Box className="div-separator" />
                <ArtistLineup
                  {...{
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                  }}
                />

                <Flex w="100%" justifyContent="flex-start">
                  <Button
                    onClick={toggleAdvanced}
                    variant="ghost"
                    _hover={{ bg: 'transparent' }}
                    _active={{ bg: 'transparent' }}
                    _focus={{ boxShadow: 'none' }}
                    p={0}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon
                      as={showAdvanced ? ChevronDownIcon : ChevronRightIcon}
                      mr={2}
                    />
                    <Text
                      ontSize={'1.3125rem'}
                      textTransform={'capitalize'}
                      color={textValue}
                      className="gordita700"
                      fontWeight={500}
                    >
                      Advanced Features
                    </Text>
                  </Button>
                </Flex>
                <Collapse in={showAdvanced} animateOpacity alignItems="center">
                  <GuestList
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />
                  <EventPassword
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />

                  <Trailer
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />
                  <ExtraPictures
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                  <Box className="div-separator" />

                  <Perks
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isEditMode,
                    }}
                  />
                  <Box className="div-separator" />

                  <Language
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isEditMode,
                    }}
                  />
                  <Box className="div-separator" />

                  <TableDetails
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                      isEditMode,
                    }}
                  />
                </Collapse>

                {_U?.userData?.userRole === 'ADMIN' && shouldShowContent && (
                  <Admin
                    {...{
                      values,
                      handleBlur,
                      handleChange,
                      setFieldValue,
                    }}
                  />
                )}
              </VStack>
            );
          }}
        </Formik>
      </Box>
      <Modal
        closeOnOverlayClick={false}
        isOpen={_.openModal}
        onOverlayClick={() => {
          setRegistrationModal((lp) => {
            return { ...lp, openModal: false, userRole: '' };
          });
        }}
        onClose={() => {
          setRegistrationModal((lp) => {
            return { ...lp, openModal: false, userRole: '' };
          });
        }}
        size={isMobile ? 'full' : { base: 'sm', md: 'md', lg: 'lg' }} // Set the size conditionally
        isCentered={true}
      >
        <ModalOverlay />
        <ModalContent
          mx={0}
          color="white.100"
          borderRadius="2.5rem"
          boxShadow={
            isMobile
              ? 'none'
              : '0px 0px 100px 20px rgba(0, 0, 0, 0.90), -1px -1px 0px 0px rgba(255, 255, 255, 0.20) inset, 1px 1px 0px 0px rgba(255, 255, 255, 0.50) inset'
          }
          bg={'rgba(0, 0, 0, 0.50)'}
          backdropFilter={'blur(150px)'}
          maxHeight="80vh" // Set the max height
          //overflowY="auto" // Allow scrolling if content exceeds max height
        >
          <ModalCloseButton />
          <ModalBody
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            //  p={isMobile ?}
          >
            {renderModal()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateEvent;
