import React, { Fragment, useEffect, useRef, useState, useMemo } from "react";
import { Box, Text, VStack, Button, useToast, HStack } from "@chakra-ui/react";
import "../../../styles/portalys/portalys.css";
import { registration } from "../../../recoil/atoms/registration";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../modules/layout/layout";
import { getEventsBySlug } from "../../../utils/actions/event";
import { isEmpty } from "lodash";
import moment from "moment";
import { useRecoilState } from "recoil";
import { ticket } from "../../../recoil/atoms/tickets";
import { user } from "../../../recoil/atoms/user";
import { theme } from "../../../styles/theme/base";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import "./eventembed.css";
import { Collapse } from "antd";
import { hexToHSL } from "../../../helpers/fucntions/getThemeColor";
import { NotificationComponent } from "../../../modules/shared/notificationComponent";
import PhoneInput from "react-phone-input-2/";
import i18n from "i18next";
import translationEN from "../locales/en.json";
import translationFR from "../locales/fr.json";
import translationES from "../locales/es.json";
import axios from "../../../utils/api/axios";
import { EventInfoComponent } from "../event-components/eventInfo";
import { MainWrapper } from "../../../styles/layout/layout";
import secrets from "../../../secrets";
import getSymbolFromCurrency from "currency-symbol-map";
import embedLogo from "../../../static/assets/images/embed-logo.svg";

i18n.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
    es: {
      translation: translationES,
    },
  },
});

export default function SimpleEmbed(props) {
  const [_R, setRegistrationModal] = useRecoilState(registration);
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const [event, setEvent] = useState();
  const [_, setTicket] = useRecoilState(ticket);
  const [_U, setUser] = useRecoilState(user);
  const toast = useToast();
  const isTablet = useMediaQuery("(max-width: 991px)");
  const isCompact = useMediaQuery("(max-width: 510px)");
  const is24HourFormat = moment.locale() !== "en";
  const TimeFormat = is24HourFormat ? "HH:mm" : "h:mm A";
  const [phoneNumber, setPhoneNumber] = useState(
    _U?.userData?.phoneNumber || ""
  );
  const [instagram, setInstagram] = useState("");

  const path = window.location.pathname;
  const isEmbed = path.includes("/embed/");
  const handlePhoneNumberChange = (value) => {
    // Check if the value is numeric or empty
    if (/^\d*$/.test(value) || value === "") {
      setPhoneNumber(value);
    }
  };

  let filteredColors =
    event?.extractedColors
      ?.sort((a, b) => b.area - a.area)
      .filter((color) => {
        // Count the number of "f"s in the color string
        let fCount = (color.hex.match(/f/g) || []).length;
        // Count the number of "f"s in the color string
        let zeroCount = (color.hex.match(/0/g) || []).length;

        // Keep the color if it has at most two "f"s
        return fCount <= 2 && zeroCount <= 3;
      }) || [];

  const { bgColor, accentColor } = hexToHSL(filteredColors?.[0]?.hex);
  const [loading, setLoading] = useState(false);


  const handleViewEvent = () => {
    const url = `${secrets.clientUrl}/event/${event.slug}`;
    window.open(url, "_blank");
  };

  const init = async () => {
    if (id) {
      try {
        const val = await getEventsBySlug(id, "");
        if (!isEmpty(val)) {
          console.log("vv", val.ticketTypes);
          setEvent(val);
          i18n.changeLanguage(val.language);
          if (val.isMintedCompletely) {
            setIsChecked(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    init();
  }, [id]);

  const findLowestPriceTicket = (tickets) => {
    return tickets?.reduce((lowest, ticket) => {
      return ticket.ticketPrice < lowest.ticketPrice ? ticket : lowest;
    }, tickets[0]); // Initialize with the first ticket
  };
  const lowestPriceTicket = findLowestPriceTicket(event?.ticketTypes);
  const lowestPrice = lowestPriceTicket ? lowestPriceTicket.ticketPrice : 0;

  const EventDetails = (
    <>
      <Box
        width={"100%"}
        paddingTop="35px"
        pos="relative"
        className="event-left-components"
      >
        <EventInfoComponent
          eventName={event?.eventName}
          accentColor={accentColor}
          startTime={event?.startTime}
          endTime={event?.endTime}
          startDate={event?.startDate}
          city={event?.city}
          location={event?.location}
          totalTickets={event?.totalTickets}
          available={event?.available}
          hideLikes={true}
          seePerks={false}
          sawPerks={false}
          isMobile={true}
          moment={moment}
          TimeFormat={TimeFormat}
          people={i18n.t("people")}
          by={i18n.t("by")}
          simpleEmbed={true}
        />
        <Box className="div-separator" />
        <HStack gap={"60px"} marginLeft={"5px"}>
          <Text
            color="var(--Colors-S---White, #FFF)"
            letterSpacing="normal"
            fontFamily="Gordita"
            fontSize="18px"
            fontWeight="700"
            lineHeight="150%"
            display={"flex"}
            textStyle="normal"
            style={{ whiteSpace: "nowrap" }}
          >
            From {`${getSymbolFromCurrency(event?.currency)}${lowestPrice}`}
          </Text>
          <Button
            fontWeight="700"
            textAlign="center"
            fontSize="1rem"
            outline="none"
            className="gordita700"
            borderRadius="50px"
            background="var(--Colors-S---White, #FFF)"
            display="flex"
            height="40px"
            minWidth="100px"
            padding="0px 25px"
            justifyContent="center"
            alignItems="center"
            gap="5px"
            color="var(--Colors-S---Black, #000)"
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
              cursor: "pointer",
            }}
            _active={{
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            _disabled={{
              bg: theme.buttons.primary.disabled,
              color: theme.buttons.primary.colorDisabled,
            }}
            onClick={handleViewEvent}
          >
            View Tickets
          </Button>
        </HStack>
      </Box>
    </>
  );

  const content =
    event !== undefined ? (
      <Box
        className="event-left-components"
        height="100vh"
        padding={"0.75rem 1.25rem 0.75rem 0.75rem"}
      >
        <HStack
          gap="30px"
          marginRight="20px"
          marginBottom="10px"
          marginLeft="10px"
          width={"100%"}
          display={"flex"}
          maxWidth={"560px"}
          flexDirection={isCompact ? "column" : "row"}
        >
          <a
            href="https://portalys.io"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              width: "32px",
              height: "32px",
              position: "absolute",
              right: "16px",
              top: "16px",
              zIndex: 1000,
            }}
          >
            <img src={embedLogo} alt={"portalys-logo"} />
          </a>

          <img
            src={event?.eventImage}
            style={{
              display: "flex",
              width: "220px",
              height: "283px",
              alignItems: "flex-end",
              gap: "3.3px",
              borderRadius: "15px",
              border:
                "1.13px solid var(--Colors-S---W---10, rgba(255, 255, 255, 0.10))",
            }}
            alt={"event-image"}
          />
          {EventDetails}
        </HStack>
      </Box>
    ) : (
      <div
        style={{
          margin: "0 auto",
          fontSize: "2rem",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        Loading..
      </div>
    );

  return <MainWrapper background={bgColor}>{content}</MainWrapper>;
}
