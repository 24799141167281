import {
  Box,
  HStack,
  Stack,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  TableContainer,
  Text,
  Image,
  Tr,
  useColorModeValue,
  Button,
  useToast,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  VStack,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "../../../../utils/api/axios";
import secrets from "../../../../secrets";
import { useRecoilState } from "recoil";
import { user } from "../../../../recoil/atoms/user";
import { theme } from "../../../../styles/theme/base";
import { getEvents } from "../../../../utils/actions/event";
import { isEmpty } from "lodash";
import { TeamMemberSchema } from "../../../../utils/schema";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@chakra-ui/icons";
import trashicon from "../../../../static/assets/images/TypeDelete.svg";
import "./teamComponent.css";

const spacing = {
  gap: 0,
  spacing: 0,
  w: "100%",
};

/**
 * The UsersComponent function displays a table of attendees or organizers, with options to view
 * details or approve organizers if the user is an admin.
 * @returns The `UsersComponent` functional component is being returned.
 */
const TeamComponent = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [_, setUser] = useRecoilState(user);
  const toast = useToast();
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [events, setEvents] = useState([]);
  const [memberId, setMemberId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedRole, setSelectedRole] = useState(""); // State to store the selected role
  const roles = ["Organizer"];
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split("T")[0]; // Format 'YYYY-MM-DD'
  const navigate = useNavigate();

  const isEventsAvailable =
    data?.filter((event) => event?.endDate >= formattedCurrentDate).length !==
    0;

  useEffect(() => {
    if (isModalOpen) {
      // Generate a new random code when the modal is opened
      setEmail("");
      setFirstName("");
      setLastName("");
      setSelectedRole("Organizer");
    }
  }, [isModalOpen]);

  const init = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("user_d"));
      const token = localStorage.getItem("x-auth-token");
      if (user?.userRole === "ADMIN") {
        await axios
          .get( "/api/events/admin", {
            headers: {
              "x-auth-token": "Bearer " + token,
            },
          })
          .then((res) => {
            const data = res.data;
            if (!isEmpty(data)) {
              const newEvents = [];
              Object.values(data).forEach((et) => {
                newEvents.push({ ...et });
              });
              console.log("newevents1", newEvents);
              setData(newEvents);
            }
          })
          .catch((err) => {
            console.log({ err });
          });
      } else {
        console.log("checkpoint2");
        const val = await getEvents(user.userId);

        if (!isEmpty(val)) {
          console.log("checkpoint3");
          const newEvents = [];
          Object.values(val).forEach((et) => {
            newEvents.push({ ...et });
          });
          console.log("newevents2", newEvents);
          setData(newEvents);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    init();
  }, []);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleButtonClick2 = () => {
    setSelectedEmail(email);
    setIsModalOpen2(true);
  };

  const handleSaveClick = async () => {
    try {
      await TeamMemberSchema.validate({ email, firstName, lastName });
      setIsEmailValid(true); // Email is valid
      const payload = {
        email,
        organizerId: _.userData?.organizerId || _.userData?.userId,
        firstName,
        lastName,
        userRole: selectedRole,
      };
      const token = localStorage.getItem("x-auth-token");

      const addMember = await axios.post(
         "/api/createTeamMember",
        payload,
        {
          headers: {
            "x-auth-token": "Bearer " + token,
          },
        }
      );
      console.log("aaaaaa", addMember);
      if (addMember.data.success) {
        console.log("checkpoint33");
        toast({
          title: "Collaborator Created Successfully",
          status: "success",
          isClosable: true,
          duration: 1500,
          position: "top-right",
        });
        getTeamMembers();
        setIsModalOpen(false);
      } else {
        console.log("checkpoint32");
        toast({
          title: "Member Creation Error",
          description: "Member might already exists",
          status: "error",
          isClosable: true,
          duration: 4000,
        });
      }
      // Continue with your save logic...
    } catch (error) {
      setIsEmailValid(false); // Email is invalid
    }
  };

  const getTeamMembers = async () => {
    try {
      const organizerId = _.userData?.organizerId || _.userData?.userId;
      let url = "/api/getTeamMembers/" + organizerId;
      const response = await axios.get(url, {
        headers: {
          "x-auth-token": "Bearer " + _?.token,
        },
      });

      setData2(response.data?.members);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTeamMembers();
  }, [_.userData?.userId]);

  const removeTeamMember = async () => {
    const token = _?.token;
    await axios
      .delete( "/api/deleteTeamMember/" + memberId, {
        headers: {
          "x-auth-token": "Bearer " + token,
        },
      })
      .then(async (res) => {
        await init();
        getTeamMembers();
        onClose();

        toast({
          title: "Collaborator Removed",
          position: "bottom",
        });
      })
      .catch((err) => {
        console.log({ err });
        onClose();
      });
  };



  const isAllEventsToggled =
    data
      ?.filter((event) => event?.endDate >= formattedCurrentDate)
      .map((event) => event?.idEvent).length === events.length;

  const commonButtonStyle = {
    display: "flex",
    height: "45px",
    padding: "15px 25px",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",
    flex: "1 0 0",
    borderRadius: "100px",
  };

  const selectedButton = {
    ...commonButtonStyle,
    background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
    color: "white",
  };

  const notSelectedButton = {
    ...commonButtonStyle,
    background: "none",
    color: "#FFFFFF99",
  };

  return (
    <Box w="100%">
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent
          bg="black.100"
          border="1px solid #333333"
          borderRadius="1.25rem"
        >
          <ModalHeader color="white.100">Cancel</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this collaborator? This action
            cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.secondary.default}
              color={theme.buttons.secondary.color}
              _hover={{ bg: theme.buttons.secondary.hover }}
              style={{
                width: "fit-content",
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              onClick={removeTeamMember}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "5rem",
                padding: "0",
              }}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box w="100%" minH="80vh">
        <Stack
          {...{ spacing }}
          flexDir="row"
          color="#FBFAFB"
          pos={"sticky"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          top={0}
          p={"30px"}
          flexWrap={"wrap"}
          gap={"10px"}
          minHeight={"110px"}
          zIndex={10}
          w="100%"
          fontWeight={700}
          bg={"rgba(0, 0, 0, 0.50)"}
          backdropFilter={"blur(150px)"}
          className={"team-component-header-container"}
        >
          <Box className="team-component-header-title" fontSize={"32px"}>
            Team
          </Box>
          <Box
            className="team-component-header-filters"
            display={"flex"}
            justifyContent={"center"}
            flexWrap={"wrap"}
            gap={"10px"}
          >
            <Box
              sx={{
                display: "flex",
                maxWidth: "416px",
                padding: "5px",
                alignItems: "flex-start",
                gap: "5px",
                borderRadius: "100px",
                border:
                  "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
                background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
                backdropFilter: "blur(75px)",
              }}
            >
              <Button
                sx={notSelectedButton}
                onClick={() => {
                  navigate("/verifiers");
                }}
              >
                Verifiers
              </Button>
              <Button sx={selectedButton}>Collaborators</Button>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              width="18.1875rem"
              padding="var(--spacing-spacing-s, 0.9375rem) var(--spacing-spacing-m, 1.25rem)"
              gap="var(--spacing-spacing-s, 0.9375rem)"
              borderRadius="var(--radius-radius-full, 6.25rem)"
              border="1px solid rgba(255, 255, 255, 0.20)"
              height="3.375rem"
            >
              <SearchIcon color="#fff" />
              <Input
                placeholder="Search Collaborator"
                focusBorderColor="none"
                border={"none"}
                color="#fff"
                className="gordita400"
                outline="none"
                type="text"
                _placeholder={{
                  color: "rgba(255, 255, 255, 0.40)",
                }}
              />
            </Box>
          </Box>

          <Button
            onClick={handleButtonClick}
            sx={theme.buttons.primary.shape}
            bg={theme.buttons.primary.default}
            color={theme.buttons.primary.color}
            _hover={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.hoverBoxShadow,
            }}
            _active={{
              bg: theme.buttons.primary.hover,
              boxShadow: theme.buttons.primary.activeBoxShadow,
            }}
            className="team-component-header-button"
          >
            Add Collaborator
          </Button>
        </Stack>
        <Box w="100%" px={"30px"} marginBottom={"30px"}>
          <TableContainer>
            <Table
              style={{
                borderCollapse: "separate",
                borderSpacing: "0 18px",
                marginBottom: "30px",
              }}
              variant="unstyled"
            >
              <Thead
                borderBottom="1px solid"
                borderColor="rgba(153, 159, 187,0.2)"
              >
                <Tr>
                  {["Name", "Email", "Role", "Phone Number", "Actions"].map(
                    (column, index) => {
                      return (
                        <Th
                          key={index}
                          paddingBottom={"20px"}
                          textTransform="capitalize"
                          color="var(--surface-surface-secondary, #959597)" // Added color
                          fontFamily="Gordita" // Added font family
                          fontSize="1rem" // Added font size
                          fontStyle="normal"
                          fontWeight={400} // Added font weight
                          lineHeight="150%" // Added line height
                          letterSpacing="-0.01rem" // Added letter spacing
                          position="relative"
                          textAlign={"center"}
                          border={"none"}
                          borderRadius="0rem" // Added border radius
                          borderRight="0px solid rgba(255, 255, 255, 0.10)" // Added border right
                          borderBottom="1px solid rgba(255, 255, 255, 0.10)" // Added border bottom
                          borderLeft="0px solid rgba(255, 255, 255, 0.10)" // Added border left
                          background="rgba(0, 0, 0, 0.50)" // Added background
                          backdropFilter="blur(75px)" // Added blur
                        >
                          {column}
                        </Th>
                      );
                    }
                  )}
                </Tr>
              </Thead>
              <Tbody marginBottom={"30px"}>
                {Object.values(data2)?.map(
                  (
                    {
                      firstName,
                      lastName,
                      email,
                      userRole,
                      phoneNumber,
                      userId,
                      organizerId,
                    },
                    index
                  ) => {
                    const isOwner = userId === _.userData?.organization?.workspaceOwner;
                    const isAllowed = _.userData?.userId === _.userData?.organization?.workspaceOwner;

                    // Convert the events JSON object into an array of event IDs
                    return (
                      <Tr overflow="hidden" color="white.100" key={index}>
                        <Td
                          m={0}
                          py={0}
                          pl={20}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderLeft={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopLeftRadius={"0.9375rem"}
                          borderBottomLeftRadius={"0.9375rem"}
                          overflow={"hidden"}
                          maxWidth={"15rem"}
                        >
                          <HStack py={0} spacing={10}>
                            <Text
                              className="gordita400"
                              color="#FFF" // Added color
                              fontFeatureSettings="'ss08' on, 'ss04' on" // Added font feature settings
                              fontFamily="Gordita" // Added font family
                              fontSize="1.125rem" // Added font size
                              fontStyle="normal"
                              fontWeight={700} // Added font weight
                              lineHeight="110%" // Added line height
                              letterSpacing="-0.01125rem" // Added letter spacing
                            >
                              {`${firstName + " " + lastName || ""}`}
                            </Text>
                          </HStack>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {email || ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          maxWidth={"14rem"}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {userRole || ""}{" "}
                            {isOwner ? "(Workspace Owner)" : ""}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          py={0}
                          textAlign={"center"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          position={"relative"}
                          alignContent={"center"}
                          justifyContent={"center"}
                        >
                          <Text
                            fontSize={14}
                            className="gordita400"
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {phoneNumber || "Hasn't Signed In Yet"}
                          </Text>
                        </Td>
                        <Td
                          m={0}
                          pl={0}
                          textAlign={"end"}
                          border={"none"}
                          borderY={"1px solid rgba(255, 255, 255, 0.10)"}
                          borderTopRightRadius={"0.9375rem"}
                          borderBottomRightRadius={"0.9375rem"}
                        >
                          <HStack
                            justifyContent="center"
                            alignItems="center"
                            spacing={24}
                          >
                            <Button
                              cursor={
                                isAllowed
                                  ? isOwner
                                    ? "not-allowed"
                                    : "pointer"
                                  : "not-allowed"
                              }
                              onClick={() => {
                                if (!isOwner && isAllowed) {
                                  setMemberId(userId);
                                  onOpen();
                                }
                              }}
                              justifyContent="center"
                              alignItems="center"
                              spacing={0}
                              opacity={
                                isAllowed ? (isOwner ? "0.5" : "1") : "0.5"
                              }
                              sx={theme.fixedButtons.secondary.shape}
                              bg={theme.buttons.secondary.default}
                              border="1px solid rgba(255, 255, 255, 0.20)"
                              backdropFilter="blur(100px)"
                            >
                              <Image alt="" src={trashicon} />
                            </Button>
                          </HStack>
                        </Td>
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        size="xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          bg="black"
          border="1px solid #333333"
          borderColor="#333333"
          borderRadius="1.25rem"
        >
          <ModalHeader>Add Collaborator</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack
              display={"flex"}
              alignItems={"start"}
              justifyContent={"center"}
              mt={"3rem"}
              gap={"50px"}
            >
              <VStack w={"100%"} maxW={"25rem"}>
                <Text w={"100%"} fontWeight={"bold"}>
                  Info
                </Text>
                <Input
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  borderRadius={"20px"}
                  borderColor={"rgba(255, 255, 255, 0.20)"}
                  color="#FFFFFF"
                  focusBorderColor="#FFFFFF"
                  _hover={{
                    borderColor: "#FFFFFF !important",
                  }}
                  _placeholder={{
                    color: "rgba(255, 255, 255, 0.40)",
                  }}
                />
                <Input
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  borderRadius={"20px"}
                  borderColor={"rgba(255, 255, 255, 0.20)"}
                  color="#FFFFFF"
                  focusBorderColor="#FFFFFF"
                  _hover={{
                    borderColor: "#FFFFFF !important",
                  }}
                  _placeholder={{
                    color: "rgba(255, 255, 255, 0.40)",
                  }}
                />
                {!isEmailValid && (
                  <div style={{ color: "red" }}>Invalid email address</div>
                )}
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  borderRadius={"20px"}
                  borderColor={"rgba(255, 255, 255, 0.20)"}
                  color="#FFFFFF"
                  focusBorderColor="#FFFFFF"
                  _hover={{
                    borderColor: "#FFFFFF !important",
                  }}
                  _placeholder={{
                    color: "rgba(255, 255, 255, 0.40)",
                  }}
                />
                <Select
                  placeholder="Select Role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  borderRadius={"20px"}
                  borderColor={"rgba(255, 255, 255, 0.20)"}
                  color="#FFFFFF"
                  focusBorderColor="#FFFFFF"
                  _hover={{
                    borderColor: "#FFFFFF !important",
                  }}
                >
                  {roles.map((role) => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </Select>
              </VStack>
            </HStack>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => setIsModalOpen(false)}
              sx={theme.buttons.tercary.shape}
              bg={theme.buttons.tercary.bgColor}
              color={theme.buttons.tercary.color}
              _hover={{ bg: theme.buttons.tercary.hover }}
              css={{
                width: "fit-content !important",
                padding: "1.25rem",
                marginRight: "10px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveClick}
              sx={theme.buttons.primary.shape}
              bg={theme.buttons.primary.default}
              color={theme.buttons.primary.color}
              _hover={{
                bg: theme.buttons.primary.hover,
                boxShadow: theme.buttons.primary.hoverBoxShadow,
              }}
              _active={{
                boxShadow: theme.buttons.primary.activeBoxShadow,
              }}
              style={{
                width: "6rem",
              }}
            >
              Invite
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TeamComponent;
