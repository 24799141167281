import {
    Box,
    Button,
    Heading,
    Tabs, TabList, TabPanels, Tab, TabPanel,
    Code, IconButton, useClipboard
  } from "@chakra-ui/react";
  import { CopyIcon } from "@chakra-ui/icons";
  import { useEffect, useRef, useState } from "react";
  
  import { useParams } from "react-router-dom";
import { getEventsByUUID } from "../../../utils/actions/event";
import secrets from "../../../secrets";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';


  const EmbedEventComponent = () => {
    const params = useParams();
    const { id } = params;
    const [event,setEvent]=useState();
    const [isBlackTheme, setIsBlackTheme] = useState(true);
    const embedContainerRef = useRef(null);
    const [eventSlug, setEventSlug] = useState('');

    const handleCopyCode = (code) => {
      navigator.clipboard.writeText(code);
    };
  
    useEffect(() => {
        if (embedContainerRef.current) {
          embedContainerRef.current.innerHTML = isBlackTheme ? embedButtonBlack : embedButtonWhite;
          const script = document.createElement("script");
          script.src = "https://portalys-bucket-1.s3.us-east-2.amazonaws.com/checkout-button.js";
          script.id = "portalys-checkout";
          embedContainerRef.current.appendChild(script);
        }
      }, []); 
      useEffect(() => {
        if (embedContainerRef.current) {
          embedContainerRef.current.innerHTML = isBlackTheme ? embedButtonBlack : embedButtonWhite;
        }
      }, [isBlackTheme]);
    
    
      const embedPage=`
      <iframe
      src="${secrets.clientUrl}/embed/event/${eventSlug}"
      width="1000"
      height="700"
      style="border: 1px solid #bfcbda88; border-radius: 4px;"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
      style="border-radius: 32px; border: 1px solid rgba(0, 0, 0, 0.24);"
    ></iframe>`;
      const embedButtonBlack=`<a
    href="${secrets.clientUrl}/event/${eventSlug}"
    class="portalys-checkout--button-black"
    target="_blank"
    rel="noopener noreferrer"
    >
    Get Your Tickets
    </a>
    
    <script id="portalys-checkout" src="https://portalys-bucket-1.s3.us-east-2.amazonaws.com/checkout-button.js"></script>`
    
    const embedButtonWhite=`<a
    href="${secrets.clientUrl}/event/${eventSlug}"
    class="portalys-checkout--button"
    target="_blank"
    rel="noopener noreferrer"
    >
    Get Your Tickets
    </a>
    
    <script id="portalys-checkout" src="https://portalys-bucket-1.s3.us-east-2.amazonaws.com/checkout-button.js"></script>`
    
    const embedCover=`
      <iframe
      src="${secrets.clientUrl}/simple-embed/event/${eventSlug}"
      width="575"
      height="350"
      allowfullscreen=""
      aria-hidden="false"
      tabindex="0"
      style="border-radius: 32px; border: 1px solid rgba(0, 0, 0, 0.24);"

    ></iframe>`;
    const commonButtonStyle = {
        display: "flex",
        height: "45px",
        padding: "15px 25px",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
        flex: "1 0 0",
        borderRadius: "100px",
      };
    
      const selectedButton = {
        ...commonButtonStyle,
        background: "var(--colors-shades-white-10, rgba(255, 255, 255, 0.10))",
        color: "white",
      };
    
      const notSelectedButton = {
        ...commonButtonStyle,
        background: "none",
        color: "#FFFFFF99",
      };

    const init2 = async () => {
      try{
        
      const val = await getEventsByUUID(id);
      setEventSlug(val.slug);
      setEvent(val);

      }
        catch (err) {
           console.error(err);
        }
    };
  
    useEffect(() => {
      init2();
    }, []);
  
    return (
        <Box w="100%" p="30px">
        <Box w="100%" textAlign="center">
          <Heading>Embed Options for: {event?.eventName}</Heading>
        </Box>
        <Box display="flex" justifyContent="center" mt={4}>
        <Tabs variant="enclosed" width="100%" mt={4}>
        <TabList justifyContent="center">
          <Tab
            _selected={{ color: 'white', fontWeight: '900' }}
            fontWeight={'bold'}
            color="rgba(255, 255, 255, 0.64)"
          >
            Embedded Checkout
          </Tab>
          <Tab
            _selected={{ color: 'white', fontWeight: 'bold' }}
            fontWeight={'bold'}
            color="rgba(255, 255, 255, 0.64)"
          >
            Embedded Button
          </Tab>
          <Tab
            _selected={{ color: 'white', fontWeight: 'bold' }}
            fontWeight={'bold'}
            color="rgba(255, 255, 255, 0.64)"
          >
            Embedded Cover
          </Tab>
        </TabList>
          <TabPanels>
            <TabPanel>
              <p  style={{
  marginTop: '32px',
  marginBottom: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}}> Users buy ticket from your website. Modify the width, height and style to match your needs.</p>
              <Box mt={4} position="relative" p="4" borderWidth="1px" borderRadius="md" bg="rgba(255, 255, 255, 0.08)" width={"1000px"}  mx="auto" display="flex"
          alignItems="center"
          justifyContent="center">
                <SyntaxHighlighter
        language="html" // Change this to the language of your code
        style={solarizedlight} // Replace with the theme of your choice
        customStyle={{ backgroundColor: 'transparent', color: 'inherit', width:'100%', marginLeft:'85px' }}
      >
        {embedPage}
      </SyntaxHighlighter>
                <IconButton
                  aria-label="Copy to clipboard"
                  icon={<CopyIcon />}
                  position="absolute"
                  top="2"
                  right="2"
                  size="sm"
                  variant="outline"
                  onClick={() => handleCopyCode(embedPage)}
                />
              </Box>
              <Box mt={4} display="flex" justifyContent="center" alignItems="center" pt="20px"  minHeight="100px">
                <div dangerouslySetInnerHTML={{ __html: embedPage }} />
              </Box>
            </TabPanel>
            <TabPanel>
            <Box
                sx={{
                  display: "flex",
                  maxWidth: "400px",
                  mt: "10px",
                  p: "5px",
                  alignItems: "center",
                  borderRadius: "100px",
                  border: "1px solid var(--border-b-primary, rgba(255, 255, 255, 0.20))",
                  background: "var(--surface-s-glass, rgba(0, 0, 0, 0.50))",
                  backdropFilter: "blur(75px)",
                  mx:"auto", 
                  display:"flex",
                  justifyContent:"center",
                  marginBottom:'20px'
                }}
                overflow="scroll"
                gap="5px"
                className="control-header-component-filters"
              >
                <Button
                  sx={!isBlackTheme ? notSelectedButton : selectedButton}
                  onClick={() => setIsBlackTheme(true)}
                  minW="150px"
                  p="5px 25px"
                >
                  Black Button
                </Button>
                <Button sx={isBlackTheme ? notSelectedButton : selectedButton} minW="150px" p="5px 25px" onClick={() => setIsBlackTheme(false)}>
                  White Button
                </Button>
              </Box>
            <p  style={{
   marginTop: '32px',
   marginBottom: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}}> Portalys Button that sends the user to the full event page</p>
              <Box mt={4} position="relative" p="4" borderWidth="1px" borderRadius="md" bg="rgba(255, 255, 255, 0.08)" width={"1000px"}  mx="auto" display="flex"
          alignItems="center"
          justifyContent="center">
                                <SyntaxHighlighter
        language="html" // Change this to the language of your code
        style={solarizedlight} // Replace with the theme of your choice
        customStyle={{ backgroundColor: 'transparent', color: 'inherit' }}
      >
                  {isBlackTheme ? embedButtonBlack : embedButtonWhite}
                </SyntaxHighlighter>
                <IconButton
                  aria-label="Copy to clipboard"
                  icon={<CopyIcon />}
                  position="absolute"
                  top="2"
                  right="2"
                  size="sm"
                  variant="outline"
                  onClick={() => handleCopyCode(isBlackTheme ? embedButtonBlack : embedButtonWhite)}
                />
              </Box>
              <Box mt={4} width={'1000px'} display="flex" mx="auto" justifyContent="center" alignItems="center"  pt="20px" bg={!isBlackTheme ? "rgba(255, 255, 255, 0.08)" : "rgba(255, 255, 255, 0.64)"} minHeight="100px">
                <div ref={embedContainerRef} />
              </Box>
            </TabPanel>
            <TabPanel>
            <p  style={{
    marginTop: '32px',
    marginBottom: '32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}}> Event Cover that sends the user to the full event page</p>
              <Box mt={4} position="relative" p="4"  display="flex" mx="auto" justifyContent="center" alignItems="center" borderWidth="1px" borderRadius="md" bg="rgba(255, 255, 255, 0.08)" width={'1000px'}>
              <SyntaxHighlighter
        language="html" // Change this to the language of your code
        style={solarizedlight} // Replace with the theme of your choice
        customStyle={{ backgroundColor: 'transparent', color: 'inherit' }}
      >
                  {embedCover}
                </SyntaxHighlighter>
                <IconButton
                  aria-label="Copy to clipboard"
                  icon={<CopyIcon />}
                  position="absolute"
                  top="2"
                  right="2"
                  size="sm"
                  variant="outline"
                  onClick={() => handleCopyCode(embedCover)}
                />
              </Box>
              <Box mt={4} display="flex" justifyContent="center" alignItems="center" pt="20px"  mx="auto"  minHeight="100px" width={'1000px'} border="1px solid #bfcbda88" borderRadius="4px">
                <div dangerouslySetInnerHTML={{ __html: embedCover }} />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
        </Box>
      </Box>
    );
  };
  
  export default EmbedEventComponent;
  