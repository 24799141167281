import {
    Box,
    Heading,
    Text,
    Image,
    HStack,
    VStack
  } from "@chakra-ui/react";

  import twitterIcon from "../../../static/assets/images/twitter.svg";
  import instagramIcon from "../../../static/assets/images/instagram.svg";
  import linkedinIcon from "../../../static/assets/images/socialIcon/linkedin.svg";

export const EventCreatorComponent = ({
    eventCreator,
    handleProfileClick,
    organizedBy,
    followers,
    isMobile,
    isTablet,

  }) => 
(
<Box>
<Box className="darkcard">
  <Heading
    as="h4"
    size="md"
    color="#FFFFFF"
    fontSize="21px"
  >
    {eventCreator}
  </Heading>
  <HStack
    marginTop="25px"
    display="flex"
    className="organizedcard"
    w={"100%"}
  >
    <Box
      style={{ cursor: "pointer" }}
      onClick={handleProfileClick}
      w={"100%"}
      // maxW={"578px"}
      minW={"300px"}
      height={"65px"}
      borderRadius={"100px"}
      border={"1px solid #FFFFFF1A"}
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      padding={"0 1.25rem 0 0.31rem"}
      _hover={{
        borderColor: "#FFFFFF",
      }}
      _active={{
        bg: "rgba(255, 255, 255, 0.05)",
      }}
      transition={"all 0.3s ease-in-out"}
    >
      <Box
        display={"flex"}
        justifyContent={"start"}
        alignItems={"center"}
        gap={"1rem"}
        w={"100%"}
        overflow={"hidden"}
      >
        <Image
          borderRadius="100%"
          width="55px"
          height="55px"
          src={organizedBy?.image}
        />
        <VStack
          alignItems="flex-start"
          width="75%"
          spacing={0}
        >
          <Text
            color="#FFFFFF"
            fontSize="19px"
            fontWeight="500"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {`${organizedBy?.name}`}
          </Text>
          <Text
            color="rgba(255, 255, 255, 0.60)"
            fontFamily="Gordita"
            fontSize="0.875rem"
            fontStyle="normal"
            fontWeight={400}
            lineHeight="150%" /* 1.3125rem */
            letterSpacing="-0.00875rem"
          >
            {`${
              organizedBy?.totalFollowers
            } ${followers}`}
          </Text>
        </VStack>
      </Box>
      {(organizedBy?.socialLinks
        ?.instagram ||
        organizedBy?.socialLinks?.twitter ||
        organizedBy?.socialLinks
          ?.linkedin) && (
        <Box
          display={"flex"}
          alignItems={"center"}
          gap={"1rem"}
        >
          {organizedBy?.socialLinks
            ?.twitter && (
            <a
              href={`https://twitter.com/${organizedBy?.socialLinks?.twitter}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                src={twitterIcon}
                width={"24px"}
                height={"24px"}
              />
            </a>
          )}
          {organizedBy?.socialLinks
            ?.instagram && (
            <a
              href={`https://www.instagram.com/${organizedBy?.socialLinks?.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                src={instagramIcon}
                width={"24px"}
                height={"24px"}
              />
            </a>
          )}

          {organizedBy?.socialLinks
            ?.linkedin && (
            <a
            href={`https://${organizedBy?.socialLinks?.linkedin}`}
            target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <Image
                src={linkedinIcon}
                width={"24px"}
                height={"24px"}
              />
            </a>
          )}
        </Box>
      )}
    </Box>
  </HStack>
</Box>
{(isMobile || isTablet) && (
  <Box className="div-separator" />
)}
</Box>
);