import {
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import axios from "../../utils/api/axios";
import { React, useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import DashboardLayout from "../../modules/layout/dashboard";
import { user } from "../../recoil/atoms/user";
import facebook from "../../static/assets/images/fb.svg";
import "../../styles/profile/profile.css";
import secrets from "../../secrets";
import { useNavigate } from "react-router-dom";

/**
 * This is a React component that displays details of an organizer, including their name, email, team
 * size, goals, and website.
 * @param props - props is an object containing the properties passed down to the OrganizerDetail
 * component from its parent component.
 * @returns The `OrganizerDetail` component is being returned.
 */
export default function OrganizerDetail(props) {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();

  const [show, setShow] = useState(1);
  const [_, setUser] = useRecoilState(user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [website, setWebsite] = useState("");
  const [socialLinks, setSocialLinks] = useState([]);
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [data, setData] = useState({});
  const [attendees, setAttendees] = useState({});

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [id]);

  const getData = async () => {
    const response = await axios.get("/api/dashboard/getOrganizer/" + id, {
      headers: {
        "x-auth-token": _?.token,
      },
    });
    const data = response.data;
    if (data) {
      setData(data);
    }
  };
  const getAttendeeData = async () => {
    const response = await axios.get(
      "/api/dashboard/downloadAttendeeList/" + id,
      {
        headers: {
          "x-auth-token": _?.token,
        },
      }
    );
    const attendees = response?.data;
    downloadCSV(attendees);
  };
  const downloadCSV = (attendees) => {
    const csvData = arrayToCSV(attendees); // Assuming data is an array of objects
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (navigator.msSaveBlob) {
      // For Internet Explorer
      navigator.msSaveBlob(blob, "attendees.csv");
    } else {
      // For other browsers
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `${data?.organizer?.name}-attendees.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };
  const arrayToCSV = (array) => {
    // Extracting only the required columns: Full Name, Email, and Phone Number
    const filteredData = array.map((obj) => ({
      "Full Name": `${obj.firstName} ${obj.lastName}`,
      Email: obj.email || "",
      "Phone Number": obj.phoneNumber || "", // Assuming phoneNumber may be null or undefined
    }));

    const header = Object.keys(filteredData[0])
      .map((key) => `"${key}"`)
      .join(",");
    const rows = filteredData.map((obj) =>
      Object.values(obj)
        .map((value) => `"${value}"`)
        .join(",")
    );

    return [header, ...rows].join("\n");
  };

  return (
    <DashboardLayout {...props}>
      {/* form */}
      <Box color="white.100" background="black" paddingBottom="150px">
        <Divider color="white" height="5px" />

        <Box display="flex" gap="30px" marginTop="30px">
          <Box
            width="30%"
            background=" linear-gradient(180deg, #1A1A1A 0%, rgba(26, 26, 26, 0) 100%)"
            borderRadius="6px"
            padding="30px 50px"
            display="flex"
            justifyContent="start"
            flexDirection="column"
            alignItems="center"
          >
            <Image width="170px" src={data?.organizer?.image} />
          </Box>

          <Box width="67%" paddingRight="65px">
            <Box>
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Name:
                </span>{" "}
                {data?.organizer?.name}
              </Text>
            </Box>

            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Email:
                </span>{" "}
                {data?.organizer?.email}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Phone Number:
                </span>{" "}
                {data?.organizer?.phoneNumber}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Website:
                </span>{" "}
                {data?.organizer?.website}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Instagram
                </span>{" "}
                : @{data?.organizer?.socialLinks?.instagram}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Twitter
                </span>{" "}
                : @{data?.organizer?.socialLinks?.twitter}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  LinkedIn
                </span>{" "}
                : {data?.organizer?.socialLinks?.linkedin}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Has started connecting Stripe?
                </span>{" "}
                : {data?.organizer?.stripeId ? "Yes" : "No"}
              </Text>
            </Box>
            <Box marginTop="18px">
              <Text fontSize="20px" color="#FFFFFF" fontWeight="500">
                <span
                  style={{ fontWeight: "bold", textDecoration: "underline" }}
                >
                  Has successfully finished connecting Stripe?
                </span>{" "}
                : {data?.organizer?.isOnboarded ? "Yes" : "No"}
              </Text>
            </Box>
          </Box>
        </Box>
        <Button onClick={getAttendeeData}>Download Attendee Data</Button>
        <Box></Box>
      </Box>{" "}
    </DashboardLayout>
  );
}
